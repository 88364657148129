/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import CopyLinkButton from './CopyLinkButton';
import MetadataButton from '../Metadata/Button';
import css from './CopyLink.scss';

function CopyLink({
  url,
  title,
}) {
  return (
    <div className={css.CopyLink}>
      <CopyLinkButton
        className={css.CopyLink__copyLinkButton}
        link={url}
      />
      {window.navigator.share && (
        <div className={css.CopyLink__moreOptions}>
          <MetadataButton
            onClick={() => window.navigator.share({ title, url })}
          />
        </div>
      )}
    </div>
  );
}

export default CopyLink;
