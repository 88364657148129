/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import { PopupMenu } from '../PopupMenu';
import Tooltip from '../Tooltip/Tooltip';
import ShareLink from './ShareLink';
import { Translation } from '../Text';
import CopyLink from './CopyLink';

function ProofShareButton({
  proofId,
  url,
  title,
  direction,
  canSendShareLink,
  heading,
}) {
  return (
    <PopupMenu
      options={popover => (
        canSendShareLink
          ? <ShareLink
            proofId={proofId}
            url={url}
            title={title}
            onSend={popover.hide}
            heading={heading}
          />
          : <CopyLink
            url={url}
            title={title}
          />
      )}
      down={direction === 'down'}
      variant="light"
    >
      {popover => (
        <div className="page__proof__toolbar__share-button">
          <Tooltip
            up
            center
            title={<Translation value="proof.utilities.share.tooltip" />}
            maxWidth={150}
            disabled={popover.isVisible}
          >
            <button
              className="page__proof__toolbar__button page__proof__toolbar__button__icon-share-link"
            >
              <InlineSVG src="img/icons/material/share-24px.svg" />
            </button>
          </Tooltip>
        </div>
      )}
    </PopupMenu>
  );
}

export default ProofShareButton;
