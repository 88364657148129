/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {Component, Fragment} from 'react';
import moment from 'moment';
import Translation from '../Text/Translation';
import ButtonGroup from '../ButtonGroup';
import {Button} from '../Button';
import Ellipsis from '../Text/Ellipsis';
import PageSubheading from '../Page/Subheading';

const getTranslatedProofText = (entry) => {
  switch (entry.type) {
  case 'proof.started_from_step':
    return entry.step.position === 1000
      ? Translation.text('audit-summary.entry.proof.started_from_step.final')
      : Translation.text('audit-summary.entry.proof.started_from_step', { position: entry.step.position });
  case 'proof.step_visible':
    if (entry.step.position === 1000) {
      return Translation.text('audit-summary.entry.proof.step_visible.final');
    }
    if (entry.step.name) {
      return Translation.text('audit-summary.entry.proof.step_visible.named', { step: entry.step.number, name: entry.step.name });
    }
    return Translation.text('audit-summary.entry.proof.step_visible', { step: entry.step.number });
  default:
    return Translation.text(`audit-summary.entry.${entry.type}`, { version: entry.proof ? 'Version ' + entry.proof.version : null });
  }
};

const filterTags = (text) => {
  const regex = new RegExp('<br />', 'g');
  return text.replace(regex, ' ');
};

class SummaryDownload extends Component {
  state = {
    isClicked: false,
  };

  getCSVObject() {
    const {
      summary,
      proofType,
    } = this.props;
    const csvObj = [];

    summary.map((entry) => {
      const proofEntry = entry.type.startsWith('proof.');
      const userEntry = entry.type.startsWith('user.');
      const formatedDate = moment(entry.date).format('LLLL');
      if (proofEntry) {
        // eslint-disable-next-line no-param-reassign
        entry.type = (proofType !== 'proof') ? entry.type.replace('proof', proofType) : entry.type;
        csvObj.push(this.filterObj({
          proof: filterTags(getTranslatedProofText(entry)),
          reviewer: '',
          decision: '',
          date: formatedDate,
        }));
      }
      if (userEntry) {
        const multiple = !!entry.users;
        const decision = entry.decision ? Translation.text(`audit-summary.entry.decision.${entry.decision}`) : '';
        const editorEmail = entry.editor ? entry.editor.email : '';
        let userEmail = entry.user ? entry.user.email : '';
        let translatedUserText = Translation.text(`audit-summary.entry.${entry.type}`, { editorEmail });

        if (entry.type === 'user.finished') {
          userEmail += ' (' + entry.role + ')';
          translatedUserText = Translation.text(`audit-summary.entry.user.finished.button.${entry.button}${multiple ? '.multiple' : ''}`);
        }

        if (entry.type === 'user.user_viewed') {
          if (multiple) {
            translatedUserText = Translation.text(proofType === 'brief' ? 'audit-summary.entry.user.viewed_brief.multiple' : 'audit-summary.entry.user.viewed_proof.multiple');
          } else {
            translatedUserText = Translation.text(proofType === 'brief' ? 'audit-summary.entry.user.viewed_brief' : 'audit-summary.entry.user.viewed_proof');
          }
        }

        if (entry.type === 'user.comments_imported') {
          let translationStringKey;
          // replyCount could be undefined
          if (entry.commentCount === 0 && !entry.replyCount) {
            translationStringKey = 'audit-summary.entry.user.comments_imported.none';
          } else if (entry.commentCount === 0) {
            translationStringKey = entry.replyCount > 1 ? 'audit-summary.entry.user.comments_imported.replies' : 'audit-summary.entry.user.comments_imported.reply';
          } else if (!entry.replyCount) {
            translationStringKey = entry.commentCount > 1 ? 'audit-summary.entry.user.comments_imported.comments' : 'audit-summary.entry.user.comments_imported.comment';
          } else if (entry.replyCount === 1) {
            translationStringKey = entry.commentCount > 1 ? 'audit-summary.entry.user.comments_imported.comments-with-reply' : 'audit-summary.entry.user.comments_imported.comment-with-reply';
          } else if (entry.replyCount > 1) {
            translationStringKey = entry.commentCount > 1 ? 'audit-summary.entry.user.comments_imported.comments-with-replies' : 'audit-summary.entry.user.comments_imported.comment-with-replies';
          }
          translatedUserText = Translation.text(translationStringKey, {
            commentCount: entry.commentCount,
            replyCount: entry.replyCount,
            sourceType: entry.sourceType,
            sourceName: entry.sourceName,
          });
        }

        csvObj.push(this.filterObj({
          proof: '',
          reviewer: userEmail + ' ' + translatedUserText,
          decision,
          date: formatedDate,
        }));
      }
      return csvObj;
    });
    return csvObj;
  }

  updateClick = () => {
    this.setState({
      isClicked: true,
    });
  }

  filterObj({proof, reviewer, decision, date}) {
    let obj = {};
    const { viewType, hasDecisionsEnabled } = this.props;

    if (viewType === 'checklist') {
      obj = {
        Checklist: proof,
        User: reviewer,
        Date: date,
      };
    } else if (hasDecisionsEnabled) {
      obj = {
        Proof: proof,
        User: reviewer,
        Decision: decision,
        Date: date,
      };
    } else {
      obj = {
        Proof: proof,
        User: reviewer,
        Date: date,
      };
    }
    return obj;
  }

  downloadCSV() {
    this.updateClick({
      isClicked: true,
    });
    const {proofId, proofTitle} = this.props;
    const csvFileObject = this.getCSVObject();
    const csvFile = window.generalfunctions_csv(csvFileObject);
    // eslint-disable-next-line no-undef
    window.downloadFile(`${proofTitle}.csv`, new Blob([csvFile]));
    this.props.onCancel(false);
    window.__pageproof_bridge__.SegmentIo.track(57, {'Proof ID': proofId});
  }

  render() {
    const {
      onCancel,
    } = this.props;

    const {isClicked} = this.state;

    return (
      <div
        style={{
          backgroundColor: '#e6e6e6',
          padding: 30,
          paddingTop: 1,
        }}
      >
        <PageSubheading
          title="Are you sure?"
          description={<Translation value="audit-summary.download.message" />}
          center
        />
        <ButtonGroup align="center">
          <Button
            variant="text"
            label={<Translation value="button.cancel" />}
            onClick={() => onCancel(false)}
          />
          <Button
            variant="primary"
            label={
              <Fragment>
                <Translation value={isClicked
                  ? 'button.yes-export.active'
                  : 'button.yes-export'}
                />
                &nbsp;
                {isClicked && <Ellipsis />}
              </Fragment>
            }
            onClick={() => this.downloadCSV()}
          />
        </ButtonGroup>
      </div>
    );
  }
}

export default SummaryDownload;
