/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Spinner from './Spinner';

function InlineSpinner({
  size,
  color,
  ...props,
}) {
  return (
    <span
      {...props}
      className={classname('InlineSpinner', props.className)}
    >
      <div className="InlineSpinner__spacer" style={{width: size}} />
      <Spinner color={color} size={size} />
    </span>
  );
}

InlineSpinner.defaultProps = Spinner.defaultProps;

if (process.env.NODE_ENV !== 'production') {
  InlineSpinner.propTypes = {
    size: PropTypes.number,
  };
}

export default InlineSpinner;
