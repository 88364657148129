/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut';
import Tooltip from '../Tooltip';
import { Translation } from '../Text';
import IconButton from './IconButton';
import css from './ExitModeButton.scss';

function ExitModeButton({ className, onExit, tooltipTranslationKey, tooltip }) {
  useKeyboardShortcut('esc', (event) => {
    event.preventDefault();
    onExit();
  });

  return (
    <Tooltip
      title={tooltip || <Translation value={tooltipTranslationKey} />}
      up
      right
    >
      <IconButton
        onClick={() => onExit()}
        className={classname(css.ExitModeButton, className)}
        src="/img/icons/material/outlined/modified/exit_to_app-24px-left.svg"
      />
    </Tooltip>
  );
}

ExitModeButton.propTypes = {
  className: PropTypes.string,
  onExit: PropTypes.func.isRequired,
  tooltipTranslationKey: PropTypes.string,
  tooltip: PropTypes.instanceOf(Translation),
};

export default ExitModeButton;
