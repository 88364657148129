/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { InlineSVG } from '@jmshal/react-inline-svg';
import classname from 'classname';
import { Flex } from '../../../components/Flex';
import NextAvatar from '../../NextAvatar';
import { RevealingEmailInput } from '../../../components/RevealingInput';
import { ConfirmDeleteOption } from '../../../components/PopupMenu';
import css from './UserList.scss';
import UnstyledButton from '../../../components/Button/UnstyledButton';
import InputOptions from '../../../components/ProofSetup/components/InputOptions';
import Tooltip from '../../../components/Tooltip';
import { Translation } from '../../../components/Text';

const getEditTooltip = (isEnabled, isReadOnly) => {
  if (isEnabled) {
    if (isReadOnly) {
      return <Translation value="workflow.structure.shared-with.can-edit.readonly" />;
    }

    return <Translation value="workflow.structure.shared-with.can-edit" />;
  }

  if (isReadOnly) {
    return <Translation value="workflow.structure.shared-with.cannot-edit.readonly" />;
  }

  return <Translation value="workflow.structure.shared-with.cannot-edit" />;
};

export const UserList = ({
  users,
  hasPermissions,
  additionalExclusions,
  onAdd,
  onRemove,
  onCanEditUpdated,
  isReadOnly,
}) => (
  <Flex container direction="vertical" gap={10}>
    {users.map(user => (
      <Flex key={user.email} container gap={10} alignItems="center" className={css.UserList__row}>
        <span className={css.UserList__row__avatar}>
          <NextAvatar
            id={user.email}
            disableStatus
            size={40}
            active
          />
        </span>
        <span className={css.UserList__row__email}>
          {user.email}
        </span>
        <span className={css.UserList__row__tools}>
          {hasPermissions && (
            <Tooltip
              up
              center
              title={getEditTooltip(user.permissions.canEdit, isReadOnly)}
            >
              <UnstyledButton
                className={css.UserList__row__tools__canEdit}
                disabled={false}
                onClick={() => {
                  if (isReadOnly) {
                    return false;
                  }

                  onCanEditUpdated(user, !user.permissions.canEdit);
                  return false;
                }}
              >
                <InlineSVG
                  src="/img/icons/material/edit-24px.svg"
                  className={classname(css.UserList__row__tools__canEdit__icon, {
                    [css['UserList__row__tools__canEdit__icon--selected']]: user.permissions.canEdit,
                  })}
                />
              </UnstyledButton>
            </Tooltip>
          )}
          {!isReadOnly && (
            <InputOptions
              options={
                <ConfirmDeleteOption
                  variant="remove"
                  onClick={() => onRemove(user)}
                />
              }
            />
          )}
        </span>
      </Flex>
    ))}
    {!isReadOnly && (
      <div style={{ width: 'fit-content' }}>
        <RevealingEmailInput
          exclusions={[
            ...users.map(user => user.email),
            ...(additionalExclusions || []),
          ]}
          onAddBatch={emails => onAdd(emails)}
        />
      </div>
    )}
  </Flex>
);
