/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Comment from '../Comment';
import Translation from '../../Text/Translation';

function CreateComment({
  ...props
}) {
  return (
    <Comment
      {...props}
      placeholder={
        <Translation value="comment-create.placeholder" />
      }
      canMark={false}
      canCancel
      isEditing
      isCreate
    />
  );
}

export default CreateComment;
