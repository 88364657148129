/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { Fragment } from 'react';
import Translation from '../Text/Translation';
import StatusMessage from './components/StatusMessage';
import LockMessage from './components/LockMessage';
import DecisionSummaryContainer from '../../containers/DecisionSummaryContainer';
import MarkTodo from '../Comment/Icon/MarkTodo';
import MarkUnmarked from '../Comment/Icon/MarkUnmarked';
import css from './ModalMessage.scss';

function GatekeeperModalMessage({
  hasDecisionsEnabled,
  proofReviewStatus: reviewStatus,
  decisionSummary,
}) {
  return (
    <Fragment>
      <h1><Translation value="proof.dialog.gatekeeper.title" /></h1>
      {!hasDecisionsEnabled && !!reviewStatus.totalReviewers &&
        <div className={css.ModalMessage__message}>
          {!!reviewStatus.totalReviewers &&
            <StatusMessage reviewStatus={reviewStatus} />
          }
        </div>
      }
      {hasDecisionsEnabled &&
        <div className={css.ModalMessage__decisionSummary}>
          <DecisionSummaryContainer
            {...decisionSummary}
            hasBorder
          />
        </div>
      }
      <h3 className={css['ModalMessage__sub-heading']}>
        {<Translation value="proof.dialog.what-todo-next" />}
      </h3>
      <Translation
        value="proof.dialog.send-a-to-do-list.mark-comment"
        params={{
          icon: <MarkTodo active size={14} readOnly />,
        }}
      />
      <br className={css.ModalMessage__betweenComments} />
      <Translation
        value="proof.dialog.approve-the-proof.mark-comment"
        params={{
          icon: <MarkUnmarked active size={14} readOnly />,
        }}
      />
      <div className={css.ModalMessage__message}>
        <LockMessage />
      </div>
    </Fragment>
  );
}

export default GatekeeperModalMessage;
