/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useMemo, useRef, useState } from 'react';
import { Translation } from '../Text';
import css from './VideoPlayer.scss';
import { VideoPlayerButton } from './VideoPlayerButton';
import { VideoPlayerScrubber } from './VideoPlayerScrubber';
import { VideoPlayerQualityButton } from './VideoPlayerQualityButton';
import { VideoPlayerSpeedButton } from './VideoPlayerSpeedButton';
import { VideoPlayerVolumeButton } from './VideoPlayerVolumeButton';
import { VideoPlayerLoopingButton } from './VideoPlayerLoopingButton';
import { TooltipContext } from '../Tooltip/TooltipContext';
import { VideoPlayerTime } from './VideoPlayerTime';
import { PopupMenu, Option, Separator } from '../PopupMenu';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const supportsFullscreen = typeof window.HTMLDivElement.prototype.requestFullscreen === 'function';

export const VideoPlayer = ({
  // Fields
  isPlaying,
  isLooping,
  isMuted,
  isFullscreen,
  currentTime,
  durationTime,
  buffer,
  volume,
  playbackRate,
  comments,
  selectedCommentId,
  qualities,
  preferredQualityId,
  playbackQualityId,
  disableVolume,
  disableQuality,
  disableFrames,

  // Functions that trigger an angular digest
  setPreferredQuality,
  setVolume,
  setPlaybackRate,
  setMuted,
  setLooping,
  selectComment,
  updatePin,
  toggleFullscreen,
  pause,
  play,
  scrub,
  scrubEnd,
  toggle,
  scrubSeconds,
  scrubFrames,

  unsafe,
}) => {
  const unsafeApiRef = useRef();
  if (!unsafeApiRef.current) {
    unsafeApiRef.current = unsafe.api();
  }

  const {
    // Functions that should skip triggering an angular digest
    getCurrentTime,
    getPresentationTime,
    getNerdPresentationTime,
    getPinColor,
    canUpdateComment,
  } = unsafeApiRef.current;

  const tooltipContextValue = useMemo(() => ({
    mountElement: isFullscreen
      ? document.fullscreenElement.querySelector('.video-player-fullscreen-tooltip-mount')
      : null,
  }), [
    isFullscreen,
  ]);

  const timePresentations = {
    simple: getPresentationTime,
    smpte: getNerdPresentationTime,
  };

  const [initialTimePresentation] = useState(() => {
    if (!disableFrames) {
      const preference = window.localStorage.getItem('pageproof.app.video.time-presentation');
      if (preference && preference in timePresentations) {
        return preference;
      }
    }
    return 'simple';
  });
  const [timePresentation, _setTimePresentation] = useState(() => ({
    type: initialTimePresentation,
    fn: timePresentations[initialTimePresentation],
  }));
  const setTimePresentation = (type) => {
    _setTimePresentation({
      type,
      fn: timePresentations[type],
    });
    window.localStorage.setItem('pageproof.app.video.time-presentation', type);
  };

  const isMobile = useMediaQuery('(max-width: 750px)');

  return (
    <TooltipContext.Provider value={tooltipContextValue}>
      <div className={css.VideoPlayer}>
        <VideoPlayerScrubber
          isPlaying={isPlaying}
          currentTime={currentTime}
          getCurrentTime={getCurrentTime}
          durationTime={durationTime}
          buffer={buffer}
          timePresentation={timePresentation}
          scrub={scrub}
          scrubEnd={scrubEnd}
          comments={comments}
          getPinColor={getPinColor}
          canUpdateComment={canUpdateComment}
          selectedCommentId={selectedCommentId}
          selectComment={selectComment}
          updatePin={updatePin}
        />
        <div className={css.VideoPlayer__buttons}>
          {durationTime > 5 && !isMobile && (
            <VideoPlayerButton
              tooltip={<Translation value="proof.media-player.video.skip-backwards" />}
              iconUrl="img/content/proof/icons/video/skip_back_5.svg"
              // iconUrl="img/icons/material/fast_rewind_black_24dp.svg"
              onClick={() => scrubSeconds(-5)}
            />
          )}
          {!disableFrames && !isMobile && (
            <VideoPlayerButton
              tooltip={<Translation value="proof.media-player.video.frame-backwards" />}
              iconUrl="img/content/proof/icons/video/previous_frame.svg"
              onClick={() => scrubFrames(-1)}
            />
          )}
          <VideoPlayerButton
            tooltip={<Translation value="proof.media-player.video.play-pause" />}
            iconUrl={
              isPlaying
                ? 'img/icons/material/pause_black_24dp.svg'
                : 'img/icons/material/play_arrow-24px.svg'
            }
            onClick={() => toggle()}
          />
          {isMobile && (!disableFrames || durationTime > 5) && (
            <PopupMenu
              options={
                <Fragment>
                  {!disableFrames && (
                    <Option
                      label={<Translation value="proof.media-player.video.frame-backwards" />}
                      onClick={() => {
                        scrubFrames(-1);
                        return false;
                      }}
                    />
                  )}
                  {!disableFrames && (
                    <Option
                      label={<Translation value="proof.media-player.video.frame-forward" />}
                      onClick={() => {
                        scrubFrames(1);
                        return false;
                      }}
                    />
                  )}
                  <Separator />
                  {durationTime > 5 && (
                    <Option
                      label={<Translation value="proof.media-player.video.skip-backwards" />}
                      onClick={() => {
                        scrubSeconds(-5);
                        return false;
                      }}
                    />
                  )}
                  {durationTime > 5 && (
                    <Option
                      label={<Translation value="proof.media-player.video.skip-forward" />}
                      onClick={() => {
                        scrubSeconds(5);
                        return false;
                      }}
                    />
                  )}
                </Fragment>
              }
            >
              {menu => (
                <VideoPlayerButton
                  tooltip={menu.isVisible ? null : 'More'}
                  iconUrl="img/icons/material/more_vert_black_24dp.svg"
                  isSmall
                />
              )}
            </PopupMenu>
          )}
          {!disableFrames && !isMobile && (
            <VideoPlayerButton
              tooltip={<Translation value="proof.media-player.video.frame-forward" />}
              iconUrl="img/content/proof/icons/video/next_frame.svg"
              onClick={() => scrubFrames(1)}
            />
          )}
          {durationTime > 5 && !isMobile && (
            <VideoPlayerButton
              tooltip={<Translation value="proof.media-player.video.skip-forward" />}
              iconUrl="img/content/proof/icons/video/skip_forward_5.svg"
              // iconUrl="img/icons/material/fast_forward_black_24dp.svg"
              onClick={() => scrubSeconds(5)}
            />
          )}
          <VideoPlayerTime
            isPlaying={isPlaying}
            currentTime={currentTime}
            getCurrentTime={getCurrentTime}
            timePresentation={timePresentation}
            setTimePresentation={setTimePresentation}
            canChangeTimePresentation={!disableFrames}
            durationTime={durationTime}
            scrub={scrub}
            pause={pause}
            play={play}
          />
          {!disableVolume && (
            <VideoPlayerVolumeButton
              isMuted={isMuted}
              volume={volume}
              setVolume={setVolume}
              setMuted={setMuted}
            />
          )}
          {qualities.length > 0 && !disableQuality && (
            <VideoPlayerQualityButton
              qualities={qualities}
              preferredQualityId={preferredQualityId}
              playbackQualityId={playbackQualityId}
              setPreferredQuality={setPreferredQuality}
            />
          )}
          <VideoPlayerSpeedButton
            playbackRate={playbackRate}
            setPlaybackRate={setPlaybackRate}
          />
          <VideoPlayerLoopingButton
            isLooping={isLooping}
            setLooping={setLooping}
          />
          {supportsFullscreen && (
            <VideoPlayerButton
              tooltip={
                isFullscreen
                  ? <Translation value="proof.utilities.fullscreen-exit" />
                  : <Translation value="proof.utilities.fullscreen" />
              }
              onClick={toggleFullscreen}
              iconUrl={isFullscreen
                ? 'img/content/proof/icons/video/fullscreen_exit.svg'
                : 'img/content/proof/icons/video/fullscreen.svg'
              }
            />
          )}
        </div>
      </div>
    </TooltipContext.Provider>
  );
};
