/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import Tooltip from '../../components/Tooltip';
import { PopupMenu, Option } from '../../components/PopupMenu';
import Truncate from '../../components/Truncate';
import Translation from '../../components/Text/Translation';

const BarcodeScannerOverlay = (props) => {
  const {
    isEnabled,
    isHighlighting,
    canCreateComment,
    image,
    barcodes,
    onCreateComment,
  } = props;

  if (!isEnabled || !image || !barcodes) {
    return null;
  }

  const SVG_SCALE = 0.1;

  return (
    <div>
      {isHighlighting && (
        <svg
          viewBox={`0 0 ${image.width * SVG_SCALE} ${image.height * SVG_SCALE}`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <defs>
            <mask id="mask" x="0" y="0" width={image.width * SVG_SCALE} height={image.height * SVG_SCALE}>
              <rect x="0" y="0" width={image.width * SVG_SCALE} height={image.height * SVG_SCALE} fill="#fff" />
              {barcodes.map((barcode, index) => (
                <rect
                  key={index}
                  x={barcode.boundingBox.x * SVG_SCALE}
                  y={barcode.boundingBox.y * SVG_SCALE}
                  width={barcode.boundingBox.width * SVG_SCALE}
                  height={barcode.boundingBox.height * SVG_SCALE}
                />
              ))}
            </mask>
          </defs>
          <rect
            x="0"
            y="0"
            width={image.width * SVG_SCALE}
            height={image.height * SVG_SCALE}
            mask="url(#mask)"
            fillOpacity="0.7"
          />
        </svg>
      )}
      {barcodes.map((barcode, index) => {
        let url = null;
        if (barcode.format === 'qr_code') {
          try {
            url = new URL(barcode.rawValue);
          } catch (err) {
            // ignore
          }
        }
        return (
          <PopupMenu
            options={() => (
              <Fragment>
                <Option
                  label={<Translation value="barcode.overlay.copy-to-clipboard" />}
                  onClick={() => {
                    window.navigator.clipboard.writeText(barcode.rawValue);
                  }}
                />
                {!!url && (
                  <Option
                    label={<Translation value="barcode.overlay.open-in-new-tab" />}
                    onClick={() => {
                      window.open(url.href, '_blank');
                    }}
                  />
                )}
                <Option
                  label={<Translation value="barcode.overlay.create-comment" />}
                  onClick={() => {
                    if (canCreateComment()) {
                      onCreateComment(barcode);
                    }
                  }}
                  disabled={!canCreateComment()}
                />
              </Fragment>
            )}
          >
            {popup => (
              <div
                style={{
                  pointerEvents: 'all',
                  position: 'absolute',
                  top: (barcode.boundingBox.y / image.height) * 100 + '%',
                  left: (barcode.boundingBox.x / image.width) * 100 + '%',
                  width: (barcode.boundingBox.width / image.width) * 100 + '%',
                  height: (barcode.boundingBox.height / image.height) * 100 + '%',
                }}
              >
                <Tooltip
                  title={
                    <Fragment>
                      <div style={{ display: 'flex', gap: 6, fontSize: 14 }}>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          {barcode.format.replace(/_/g, ' ')}
                          :
                        </div>
                        <div style={{ fontFamily: 'monospace' }}>
                          <Truncate maxWidth={300}>
                            {barcode.rawValue}
                          </Truncate>
                        </div>
                      </div>
                      <div style={{ opacity: 0.7, textAlign: 'center' }}>
                        <Translation value="barcode.overlay.more-options.tooltip" />
                      </div>
                    </Fragment>
                  }
                  up
                  center
                  disabled={popup.isVisible}
                >
                  <div
                    key={index}
                    style={{
                      position: 'absolute',
                      inset: 0,
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </PopupMenu>
        );
      })}
    </div>
  );
};

export default BarcodeScannerOverlay;
