/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState } from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import PropTypes from 'prop-types';
import { PopupMenu, Option } from '../PopupMenu';
import Translation from '../Text/Translation';
import Tooltip from '../Tooltip';

const PrintOptionsButton = ({ onSelection }) => {
  const [isPopupMenuOpened, setIsPopupMenuOpened] = useState(false);

  return (
    <PopupMenu
      up
      onShow={() => setIsPopupMenuOpened(true)}
      onHide={() => setIsPopupMenuOpened(false)}
      options={() => (
        <Fragment>
          <Option
            className="page__proof__toolbar__print-button__header"
            label={<Translation value="print-options.option.title" />}
            readOnly
          />
          <Option
            label={<Translation value="print-options.option.new" />}
            onClick={() => { onSelection('new'); }}
          />
          <Option
            label={<Translation value="print-options.option.legacy" />}
            onClick={() => { onSelection('legacy'); }}
          />
        </Fragment>
      )}
      variant="light"
      offset={0}
    >
      <PrintButton showTooltip={!isPopupMenuOpened} />
    </PopupMenu>
  );
};

const PrintButton = ({ showTooltip, onClick }) => (
  <div className="page__proof__toolbar__print-button">
    <Tooltip
      maxWidth={270}
      up
      center
      title={<Translation value="dashboard.proof.options.print" />}
      disabled={!showTooltip}
    >
      <button
        type="button"
        className="page__proof__toolbar__button"
        onClick={onClick}
      >
        <InlineSVG src="img/interface/print.svg" />
      </button>
    </Tooltip>
  </div>
);

if (process.env.NODE_ENV === 'development') {
  PrintOptionsButton.propTypes = {
    onSelection: PropTypes.func.isRequired,
  };
  PrintButton.propTypes = {
    showTooltip: PropTypes.bool,
    onClick: PropTypes.func,
  };
}

export default PrintOptionsButton;

export {
  PrintButton,
};
