/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import css from './Text.scss';

const THEMES = {
  green: '#0d5e28',
};

const Text = ({
  active,
  theme,
  readOnly,
  children,
  ...props
}) => (
  <span
    {...props}
    style={{
      ...props.style,
      '--active-color': THEMES[theme],
    }}
    className={classname(props.className, css.Text, {
      [css['Text--active']]: active,
      [css['Text--read-only']]: readOnly,
    })}
  >
    {children}
  </span>
);

export default Text;
