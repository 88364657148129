/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export const walkthroughIds = {
  reviewingBasics: 'w-reviewing-basics',
  proofSetup: 'w-proof-setup',
  proofEditor: 'w-proof-editor',
  ppbirthday2024: 'w-pp-birthday-2024',
};
