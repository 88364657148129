/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Tooltip from '../../Tooltip';
import css from './FileDropperIconButton.scss';

const FileDropperIconButton = forwardRef(({
  variant,
  compact,
  color,
  onClick,
  onDragOver,
  onDrop,
  disabled,
  tooltipTitle,
}, ref) => (
  <Tooltip
    up
    center
    title={tooltipTitle}
    disabled={!tooltipTitle}
  >
    <button
      type="button"
      className={classname(css.FileDropperIconButton, css['FileDropperIconButton--' + variant], {
        [css['FileDropperIconButton--compact']]: compact,
        [css[`FileDropperIconButton--${color}`]]: color,
      })}
      onClick={onClick}
      onDragOver={onDragOver}
      onDrop={onDrop}
      disabled={disabled}
      ref={ref}
    >
      <InlineSVG
        src="/img/icons/file-dropper.svg"
        className={css.FileDropperIconButton__icon}
      />
    </button>
  </Tooltip>
));

if (process.env.NODE_ENV !== 'production') {
  FileDropperIconButton.propTypes = {
    variant: PropTypes.string,
    compact: PropTypes.bool,
    color: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    onClick: PropTypes.func,
    onDragOver: PropTypes.func,
    onDrop: PropTypes.func,
    disabled: PropTypes.bool,
    tooltipTitle: PropTypes.node,
  };
}

export default FileDropperIconButton;
