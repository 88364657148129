/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Fragment} from 'react';
import IconButton from '../../Button/IconButton';
import OptionList from '../../PopupMenu/OptionList';
import Option from '../../PopupMenu/Option';
import ConfirmDeleteOption from '../../PopupMenu/ConfirmDeleteOption';
import Popover from '../../Popover';
import Translation from '../../Text/Translation';
import css from './Options.scss';

function Options({
  isEditing,
  hasUnsavedChanges,
  canEdit,
  canCancel,
  canDelete,
  onEdit,
  onCancel,
  onDelete,
}) {
  if (!canEdit && !canDelete && !canCancel) {
    return null;
  }
  return (
    <Popover
      content={popover => (
        <OptionList
          options={
            <Fragment>
              {canCancel &&
                <Option
                  label={<Translation value="button.cancel" />}
                  onClick={() => onCancel()}
                />
              }
              {canEdit &&
                <Option
                  label={
                    <Fragment>
                      <Translation
                        value={isEditing
                          ? 'comment.options.cancel-edit'
                          : 'comment.options.edit'
                        }
                      />
                      {isEditing && hasUnsavedChanges &&
                        '*'
                      }
                    </Fragment>
                  }
                  onClick={() => onEdit(!isEditing)}
                />
              }
              {canDelete &&
                <ConfirmDeleteOption
                  onClick={onDelete}
                />
              }
            </Fragment>
          }
          onClick={popover.hide}
        />
      )}
      down
      left
      arrow
      offset={12}
    >
      <IconButton
        src="img/icons/options.svg"
        className={css.Options}
      />
    </Popover>
  );
}

export default Options;
