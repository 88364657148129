/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import InlineSVG from 'jacobmarshall-react-inline-svg';
import React, { Fragment } from 'react';
import Tooltip from '../Tooltip';
import css from './ProofFooterChecklistIcon.scss';

export const ProofFooterChecklistIcon = ({ showTooltip, onClick, name }) => (
  <div className={css.ProofFooterChecklistIcon}>
    <Tooltip
      maxWidth={150}
      up
      center
      title={(
        <Fragment>
          <b>Checklist</b>
          <br />
          {name}
        </Fragment>
      )}
      disabled={!showTooltip}
    >
      <button
        type="button"
        className="page__proof__toolbar__button"
        onClick={onClick}
      >
        <InlineSVG
          className={css.ProofFooterChecklistIcon__icon}
          src="img/interface/checklist.svg"
        />
      </button>
    </Tooltip>
  </div>
);
