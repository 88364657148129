/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Popover from '../Popover';
import { Separator } from '../PopupMenu';
import Tooltip from '../Tooltip';
import Translation from '../Text/Translation';
import css from './ColorDot.scss';

const ColorDot = ({
  selectedColor,
  colors,
  tooltipText,
  onChange,
  children,
  onCustomColorChange,
  customColor,
  hasCustomColor,
  maxWidth,
}) => (
  <ColorDot.PopoverWrapper
    selectedColor={selectedColor}
    colors={colors}
    onChange={onChange}
    onCustomColorChange={onCustomColorChange}
    customColor={customColor}
    hasCustomColor={hasCustomColor}
    maxWidth={maxWidth}
  >
    {popover => (
      <Tooltip
        up
        center
        title={<Translation value={tooltipText} />}
        maxWidth={150}
      >
        <div className={css.ColorDot}>
          <ColorDot.Dot
            className={css.ColorDot__ringWhite}
            color={selectedColor}
            onClick={popover.toggle}
          />
          {children}
        </div>
      </Tooltip>
    )}
  </ColorDot.PopoverWrapper>
);

ColorDot.PopoverWrapper = ({
  selectedColor,
  colors,
  onChange,
  children,
  offset,
  onCustomColorChange,
  customColor,
  hasCustomColor,
  maxWidth,
}) => (
  <Popover
    arrow
    up
    center
    variant="light"
    content={
      <ColorDot.ColorGrid
        colors={colors}
        selectedColor={selectedColor}
        onChange={onChange}
        onCustomColorChange={onCustomColorChange}
        customColor={customColor}
        hasCustomColor={hasCustomColor}
      />
    }
    maxWidth={maxWidth || 270}
    offset={offset}
  >
    {children}
  </Popover>
);

ColorDot.Generic = ({
  color,
  outline,
  label,
}) => (
  <div className={css.ColorDot}>
    <ColorDot.Dot
      className={classname({
        [css.ColorDot__ringWhite]: outline,
      })}
      color={color}
    />
    <ColorDot.Label
      label={label}
    />
  </div>
);

ColorDot.ColorGrid = ({
  selectedColor,
  colors,
  onChange,
  onCustomColorChange,
  customColor,
  hasCustomColor,
}) => (
  <div className={css.ColorDot__grid}>
    {colors.map(color => (
      <Fragment key={color.id}>
        {color.isLine
          ? <Separator
            styles={{
              width: '100%',
            }}
            variant="light"
          />
          : <ColorDot.Dot
            color={color.normal}
            selected={selectedColor}
            disabled={color.disabled}
            className={selectedColor === color.normal && css.ColorDot__ringGrey}
            onClick={() => onChange(color)}
          />
        }
      </Fragment>
    ))}
    {hasCustomColor && (
      <div style={{ position: 'relative' }}>
        <span className={css.ColorDot__colorSeparator} />
        {(!customColor || selectedColor === customColor) && (
          <Fragment>
            <InlineSVG
              src="img/icons/solid-red-pen.svg"
              className={css.ColorDot__editColorIcon}
            />
            <input
              type="color"
              value={customColor}
              onClick={event => event.stopPropagation()}
              onChange={(event) => {
                onChange({ normal: event.target.value });
                onCustomColorChange(event.target.value);
              }}
              className={css.ColorDot__inputField}
            />
          </Fragment>
        )}
        <ColorDot.Dot
          selected={customColor === selectedColor}
          color={customColor || '#777777'}
          selectedRing
          onClick={() => onChange({ normal: customColor })}
        />
      </div>
    )}
  </div>
);

ColorDot.Dot = ({ color, selected, disabled, onClick, selectedRing, className }) => (
  <div
    className={classname(css.ColorDot__container, className, selected && selectedRing ? css.ColorDot__ringGrey : null)}
    onClick={(!selected || !disabled) ? onClick : undefined}
  >
    <div
      style={{
        ...(color === 'rainbow'
          ? {
            backgroundImage: `linear-gradient(
              45deg,
              rgba(255, 0, 0, 1) 0%,
              rgba(255, 154, 0, 1) 10%,
              rgba(208, 222, 33, 1) 20%,
              rgba(79, 220, 74, 1) 30%,
              rgba(63, 218, 216, 1) 40%,
              rgba(47, 201, 226, 1) 50%,
              rgba(28, 127, 238, 1) 60%,
              rgba(95, 21, 242, 1) 70%,
              rgba(186, 12, 248, 1) 80%,
              rgba(251, 7, 217, 1) 90%,
              rgba(255, 0, 0, 1) 100%
            )`,
          }
          : { backgroundColor: color }),
        cursor: !disabled && 'pointer',
        boxShadow: color === '#ffffff' ? 'rgba(0, 0, 0, 0.2) 0 1px 2px 0' : '',
      }}
      className={css.ColorDot__dot}
    />
  </div>
);

ColorDot.Label = ({ label }) => (
  <div className={css.ColorDot__label}>
    {label}
  </div>
);

export default ColorDot;
