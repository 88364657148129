/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import ProfileSettings from '../../components/ProfileSettings';
import { getTwelveHourTime } from '../../util/date-time-utils';
import { useUserPreferences } from '../../hooks/useUserPreferences';

const ProfileUserSettingsContainer = () => {
  const [isLoading, userPreferences, { updateUserPreference }] = useUserPreferences();

  if (isLoading) {
    return null;
  }

  const {
    DEFAULT_MESSAGE_TO_REVIEWERS,
    DEFAULT_DUE_DATE,
    DEFAULT_DUE_TIME,
  } = window.__pageproof_quark__.sdk.Enum.Preference;

  const onUpdateDueTime = (dueTime) => {
    updateUserPreference(DEFAULT_DUE_TIME, dueTime ? getTwelveHourTime(dueTime) : null);
  };

  const updatePreference = id => (value) => {
    updateUserPreference(id, value);
  };

  const getSubmitCommentShortcut = () => {
    const keyboardShortcuts = userPreferences.keyboardShortcuts && userPreferences.keyboardShortcuts.value;

    if (!keyboardShortcuts || !keyboardShortcuts.submitComment) {
      return 'shift+enter';
    }

    return keyboardShortcuts.submitComment.key;
  };

  const updateSubmitCommentShortcut = (value) => {
    updateUserPreference('keyboardShortcuts', {
      ...(userPreferences.keyboardShortcuts && userPreferences.keyboardShortcuts.value),
      submitComment: {
        key: value,
      },
    });
  };

  return (
    <ProfileSettings
      dueDate={userPreferences[DEFAULT_DUE_DATE].value}
      dueTime={userPreferences[DEFAULT_DUE_TIME].value}
      messageToReviewers={userPreferences[DEFAULT_MESSAGE_TO_REVIEWERS].value}
      onUpdateDueDate={updatePreference(DEFAULT_DUE_DATE)}
      onUpdateDueTime={onUpdateDueTime}
      onUpdateMessageToReviewers={updatePreference(DEFAULT_MESSAGE_TO_REVIEWERS)}
      onUpdateSubmitCommentShortcut={updateSubmitCommentShortcut}
      submitCommentShortcut={getSubmitCommentShortcut()}
    />
  );
};

export default ProfileUserSettingsContainer;
