/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import IconButton from '../../Button/IconButton';
import css from './Close.scss';

function Close({onClose}) {
  return (
    <IconButton
      onClick={onClose}
      src="img/interface/close.svg"
      className={css.Close}
    />
  );
}

export default Close;
