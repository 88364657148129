/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useReducer, useRef, useState } from 'react';
import ImportPdfComments from '../../components/ModalMessages/ImportPdfComments';
import { sdk } from '../../util/sdk';
import getPDFJS from '../../util/pdfjs';
import { Translation } from '../../components/Text';

const IMPORT_TASK_FINISHED_STATUSES = ['failed', 'cancelled', 'completed'];
const ALLOWED_FILE_MIME_TYPES = ['application/pdf'];
const ALLOWED_FILE_EXTENSIONS = ['pdf'];
const DEFAULT_DPI = 72;
const INCH_TO_MM = 25.4;
const ASPECT_RATIO_ACCURACY = 3; // Decimal places for accuracy

// Converts a given pixel value to a whole mm value, based on 72DPI
const pixelsToMm = pixels => Math.round(pixels / DEFAULT_DPI * INCH_TO_MM);

const compareDimensions = (proofPage, pdfPage) => {
  const { PageWidth: proofPageWidth, PageHeight: proofPageHeight } = proofPage;
  const [, , pdfPageWidthPixels, pdfPageHeightPixels] = pdfPage.view;

  // pdfjs gives dimensions in pixels (72nd of an inch), proof data has dimensions in mm.
  // Convert pixels to whole mm.
  const pdfPageWidth = pixelsToMm(pdfPageWidthPixels);
  const pdfPageHeight = pixelsToMm(pdfPageHeightPixels);

  // Rounding aspect ratio to 3 decimal places to avoid false positives
  const pdfAspectRatio = +(pdfPageHeight / pdfPageWidth).toFixed(ASPECT_RATIO_ACCURACY);
  const proofAspectRatio = +(proofPageHeight / proofPageWidth).toFixed(ASPECT_RATIO_ACCURACY);

  let dimensionsMatch = true;
  let aspectsMatch = true;
  if (proofPageWidth !== pdfPageWidth || proofPageHeight !== pdfPageHeight) {
    dimensionsMatch = false;
    if (pdfAspectRatio !== proofAspectRatio) {
      aspectsMatch = false;
    }
  }

  return {
    dimensionsMatch,
    aspectsMatch,
  };
};

const optionsReducer = (state, action) => {
  switch (action) {
  case 'toggleIsPrivate':
    return {
      ...state,
      isPrivate: !state.isPrivate,
    };
  case 'toggleIncludeStatuses':
    return {
      ...state,
      includeStatuses: !state.includeStatuses,
    };
  case 'toggleSkipPreviouslyImported':
    return {
      ...state,
      skipPreviouslyImported: !state.skipPreviouslyImported,
    };
  default:
    throw new Error('Unknown action: ' + action);
  }
};

const ImportPdfCommentsContainer = (props) => {
  const { commentPermissions, isAdminOnProof, proofId, onClose, proofDimensions, proofPageCount } = props;
  const { canCreatePrivateComment, canSetCommentStatus } = commentPermissions;

  const [options, updateOption] = useReducer(optionsReducer, {
    isPrivate: false,
    includeStatuses: canSetCommentStatus,
    skipPreviouslyImported: true,
  });

  const { isPrivate, includeStatuses, skipPreviouslyImported } = options;

  const [importStatus, setImportStatus] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [importTask, setImportTask] = useState();
  const [validation, setValidation] = useState({
    isPending: false,
    errorMessages: [],
    warningMessages: [],
  });

  const [uploadProgress, _setUploadProgress] = useState(0);
  const uploadProgressRef = useRef(uploadProgress);
  const setUploadProgress = (data) => {
    uploadProgressRef.current = data;
    _setUploadProgress(data);
  };

  const fakeUploadProgressInterval = useRef(null);
  const fakeUploadProgress = () => {
    fakeUploadProgressInterval.current = setInterval(() => {
      if (uploadProgressRef.current < 50) {
        setUploadProgress(uploadProgressRef.current + 1);
      } else {
        clearInterval(fakeUploadProgressInterval.current);
      }
    }, 200);
  };

  const pollTaskInterval = useRef(null);
  const startPollingTask = (taskId) => {
    pollTaskInterval.current = setTimeout(function tick() {
      sdk.comments.import.info(taskId)
        .then((task) => {
          setImportTask(task);

          if (pollTaskInterval.current && !IMPORT_TASK_FINISHED_STATUSES.includes(task.status)) {
            pollTaskInterval.current = setTimeout(tick, 500);
          } else {
            setImportStatus(task.status);
            pollTaskInterval.current = null;
          }
        });
    }, 500);
  };

  // Clear the fakeUploadProgressInterval on unmount
  useEffect(() => () => {
    clearInterval(fakeUploadProgressInterval.current);
    clearTimeout(pollTaskInterval.current);
    pollTaskInterval.current = null;
  }, []);

  const onDiscardFile = () => {
    setSelectedFile(null);
    setValidation({
      isPending: false,
      errorMessages: [],
      warningMessages: [],
    });
  };

  const onSetFile = (file) => {
    setValidation({
      isPending: true,
      errorMessages: [],
      warningMessages: [],
    });
    setSelectedFile(file);

    const currentValidation = {
      isPending: false,
      errorMessages: [],
      warningMessages: [],
    };

    const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
      currentValidation.errorMessages.push(<Translation value="proof.import-comments.error.unsupported-format" />);
      setValidation(currentValidation);
      return;
    }

    let dimensionsMatch = true;
    let aspectsMatch = true;
    const blobUrl = URL.createObjectURL(file);

    getPDFJS()
      .then(pdfjs => pdfjs.getDocument(blobUrl).promise)
      .then((document) => {
        if (proofPageCount !== document.numPages) {
          currentValidation.errorMessages.push(<Translation value="proof.import-comments.error.page-count-mismatch" />);
        }

        return Promise.allSettled(new Array(document.numPages)
          .fill()
          .map((_, index) => document
            .getPage(index + 1)
            .then((page) => {
              // proofDimensions is unreliable, so we only compare dimensions if we have a value for the page
              if (proofDimensions.length > index) {
                const dimensionCheckResults = compareDimensions(proofDimensions[index], page);
                if (!dimensionCheckResults.dimensionsMatch) {
                  dimensionsMatch = false;
                }
                if (!dimensionCheckResults.aspectsMatch) {
                  aspectsMatch = false;
                }
              }
            })));
      })
      .catch(() => {
        // The 'InvalidPDFException' that we are interested in is being swallowed
        currentValidation.errorMessages.push(<Translation value="proof.import-comments.error.invalid-file" />);
      })
      .finally(() => {
        if (!dimensionsMatch) {
          currentValidation.warningMessages.push(!aspectsMatch
            ? <Translation value="proof.import-comments.warning.aspect-ratio-mismatch" />
            : <Translation value="proof.import-comments.warning.dimension-mismatch" />);
        }
        setValidation(currentValidation);
        URL.revokeObjectURL(blobUrl);
      });
  };

  const onStartImport = () => {
    setImportStatus('importing');
    fakeUploadProgress();
    sdk.comments.import.fromFile(selectedFile, {
      fileName: selectedFile.name,
      proofId,
      skipPreviouslyImported,
      includeStatuses: includeStatuses && !isPrivate,
      isPrivate,
    }, (callbackUploadProgress) => {
      if (callbackUploadProgress < uploadProgressRef.current) {
        return;
      }

      clearInterval(fakeUploadProgressInterval.current);
      setUploadProgress(callbackUploadProgress);
    }).then((task) => {
      setImportTask(task);
      startPollingTask(task.id);
    }).catch((err) => {
      const importTaskId = (importTask && importTask.id) || (err && err.importTaskId) || null;
      if (!pollTaskInterval.current) {
        if (importTaskId) {
          startPollingTask(importTaskId);
        } else {
          setImportStatus('failed');
        }
      }
    });
  };

  return (
    <ImportPdfComments
      options={options}
      onUpdateOption={updateOption}
      onClose={onClose}
      importTask={importTask}
      onStartImport={onStartImport}
      file={selectedFile}
      onSetFile={onSetFile}
      onDiscardFile={onDiscardFile}
      importStatus={importStatus}
      progress={Math.round((uploadProgress * 0.3) + (importTask ? (importTask.progressPercentage * 0.7) : 0))}
      canCreateAsPrivate={canCreatePrivateComment}
      canIncludeStatuses={canSetCommentStatus}
      isAdminOnProof={isAdminOnProof}
      acceptedMimeTypes={ALLOWED_FILE_MIME_TYPES.join(',')}
      validation={validation}
      canStartImport={selectedFile && !validation.isPending && !validation.errorMessages.length}
    />
  );
};

export default ImportPdfCommentsContainer;
