/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import propTypes from 'prop-types';
import css from './Truncate.scss';
import Tooltip from '../Tooltip';
import { onlyWhenTruncated } from '../Tooltip/Tooltip';
import WrapConditionally from '../WrapConditionally';

function Truncate({
  maxWidth,
  wrapOnClick,
  showTooltipWhenTruncated,
  children,
}) {
  const [shouldWrap, setShouldWrap] = useState(false);

  return (
    <WrapConditionally
      condition={showTooltipWhenTruncated}
      wrapper={(
        <Tooltip
          title={children}
          down
          center
          onBeforeOpen={onlyWhenTruncated()}
        />
      )}
    >
      {/* The effect of this onClick is not needed when using a screen reader */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={css.Truncate}
        onClick={() => {
          if (wrapOnClick) {
            setShouldWrap(!shouldWrap);
          }
        }}
        style={{
          maxWidth,
          whiteSpace: (wrapOnClick && shouldWrap) ? 'normal' : 'nowrap',
        }}
      >
        {children}
      </div>
    </WrapConditionally>
  );
}

if (process.env.NODE_ENV !== 'production') {
  Truncate.propTypes = {
    maxWidth: propTypes.number.isRequired,
    showTooltipWhenTruncated: propTypes.bool,
    wrapOnClick: propTypes.bool,
  };
}

export default Truncate;
