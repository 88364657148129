/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { InlineSVG } from '@jmshal/react-inline-svg';
import classname from 'classname';
import Popover from '../Popover';
import { Option, OptionList, Separator } from '../PopupMenu';
import menuConfig from '../../resources/giftMenu.json';
import css from './GiftMenu.scss';
import IconButton from '../Button/IconButton';
import Tooltip from '../Tooltip';

const GiftMenu = () => {
  const now = new Date();

  const isVisible = useMemo(() => {
    if (menuConfig.meta.startDate) {
      const startDate = new Date(menuConfig.meta.startDate);
      startDate.setHours(0, 0, 0, 0);
      if (now < startDate) {
        return false;
      }
    }

    if (menuConfig.meta.endDate) {
      const endDate = new Date(menuConfig.meta.endDate);
      endDate.setHours(23, 59, 59, 999);
      if (now > endDate) {
        return false;
      }
    }

    return true;
  }, []);

  const isReleased = useMemo(() => {
    if (menuConfig.meta.releaseDate) {
      const releaseDate = new Date(menuConfig.meta.releaseDate);
      releaseDate.setHours(0, 0, 0, 0);

      if (now < releaseDate) {
        return false;
      }
    }

    return true;
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={css.GiftMenu}>
      {menuConfig.sections && isReleased
        ? (
          <Popover
            content={
              <OptionList
                className={css.GiftMenu__optionList}
                wrap={false}
              >
                {menuConfig.sections.map((section, index) => (
                  <Fragment key={section.title}>
                    <Option
                      key={section.title + ' heading'}
                      label={section.title}
                      disabled
                    />
                    {section.days.map(day => (
                      <GiftMenu.Option
                        key={day.day}
                        {...day}
                        icon={day.icon || section.icon}
                        iconColor={day.iconColor || section.iconColor}
                      />
                    ))}
                    {(index + 1 < menuConfig.sections.length) && <Separator key={section.title + ' separator'} />}
                  </Fragment>
                ))}
              </OptionList>
            }
            down
            center
            arrow
            offset={15}
          >
            <IconButton
              style={{
                width: menuConfig.meta.iconWidth,
                height: menuConfig.meta.iconHeight,
              }}
              src={menuConfig.meta.icon}
              className={classname(css.GiftMenu__button, css['GiftMenu__button--' + menuConfig.meta.iconVariant])}
              data-walkthrough-hook={menuConfig.meta.walkthroughHook}
            />
          </Popover>
        )
        : (
          <Tooltip
            down
            center
            arrow
            title={isReleased ? menuConfig.meta.tooltipAfterRelease : menuConfig.meta.tooltipBeforeRelease}
          >
            <a
              href={menuConfig.meta.defaultLink}
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <IconButton
                style={{
                  width: menuConfig.meta.iconWidth,
                  height: menuConfig.meta.iconHeight,
                }}
                src={menuConfig.meta.icon}
                className={classname(css.GiftMenu__button, css['GiftMenu__button--' + menuConfig.meta.iconVariant])}
                data-walkthrough-hook={menuConfig.meta.walkthroughHook}
              />
            </a>
          </Tooltip>
        )
      }
    </div>
  );
};

GiftMenu.Option = ({ day, title, iconColor, link, icon }) => (
  <Option
    onClick={() => window.open(link || menuConfig.meta.defaultLink, '_blank')}
    label={
      <div className={css.GiftMenu__option}>
        <span className={css.GiftMenu__option__icon}>
          <InlineSVG
            src={icon || '/img/icons/calendar-today.svg'}
            fill={iconColor}
          />
          {
            !icon && <span className={css.GiftMenu__option__icon__day}>{day}</span> // for default calendar icon, we display a day number on it.
          }
        </span>
        {title}
      </div>
    }
  />
);

if (process.env.NODE_ENV !== 'production') {
  GiftMenu.Option.propTypes = {
    day: PropTypes.number,
    title: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    link: PropTypes.string,
  };
}

export default GiftMenu;
