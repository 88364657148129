/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useState, useEffect, useRef } from 'react';
import Value from './Value';
import { watch, unwatch } from './watcher';

function LocalStorage() {}
LocalStorage.Value = Value;

function useLocalStorage(key, defaultValue) {
  const loadValue = () => {
    let existingValue;
    try {
      existingValue = window.localStorage.getItem(key);
    } catch (error) {
      // continue as if no value
    }

    if (existingValue) {
      try {
        return JSON.parse(existingValue);
      } catch (err) {
        // continue as if no value
      }
    }
    if (typeof defaultValue === 'function') {
      return defaultValue();
    }
    return defaultValue;
  };

  const lastKey = useRef(key);
  const [value, setValue] = useState(() => loadValue());

  const currentValueRef = useRef();
  currentValueRef.current = value;

  useEffect(() => {
    if (key !== lastKey.current) {
      lastKey.current = key;
      setValue(loadValue());
    }

    const updateState = () => {
      setValue(loadValue());
    };
    watch(key, updateState);

    return () => {
      unwatch(key, updateState);
    };
  }, [
    key,
  ]);

  const updateValue = (newValue) => {
    if (newValue == null) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, JSON.stringify(newValue));
    }
  };

  const dispatchUpdate = (newValueOrCallback) => {
    if (typeof newValueOrCallback === 'function') {
      const newValue = newValueOrCallback(currentValueRef.current);
      updateValue(newValue);
    } else {
      updateValue(newValueOrCallback);
    }
  };

  return [
    value,
    dispatchUpdate,
    () => {
      window.localStorage.removeItem(key);
    },
  ];
}

export default LocalStorage;
export {
  Value,
  useLocalStorage,
};
