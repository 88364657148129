/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect } from 'react';
import UserProfile from '../../components/UserProfile';
import { Translation } from '../../components/Text';
import ProfileAboutMeContainer from '../ProfileAboutMeContainer';
import ProfilePasswordAndSecurityContainer from '../ProfilePasswordAndSecurityContainer';
import ProfileNotificationsContainer from '../ProfileNotificationsContainer';
import ProfileAddressBookContainer from '../ProfileAddressBookContainer';
import IntegrationApps from '../../components/IntegrationApps';
import ProfileSettingsContainer from '../ProfileSettingsContainer';
import { normaliseUTCToISO } from '../../util/date-time-utils';

const UserProfileContainer = () => {
  const { UserService: { userData }, $location, $routeParams } = window.__pageproof_bridge__;
  const [currentTab, setCurrentTab] = useState($routeParams.tab || 'edit');

  const profileTabs = {
    edit: {
      component: <ProfileAboutMeContainer />,
      label: <Translation value="profile.edit.about-me" />,
    },
    security: {
      component: <ProfilePasswordAndSecurityContainer />,
      label: <Translation value="profile.password-security" />,
    },
    notifications: {
      component: <ProfileNotificationsContainer />,
      label: <Translation value="profile.notify-me" />,
    },
    address: {
      component: <ProfileAddressBookContainer />,
      label: <Translation value="profile.address-book" />,
    },
    apps: {
      component: <IntegrationApps type="user" />,
      label: <Translation value="profile.apps" />,
    },
    settings: {
      component: <ProfileSettingsContainer />,
      label: <Translation value="profile.settings" />,
    },
  };

  useEffect(() => {
    if ($location.path() === '/profile' && currentTab === 'edit') {
      $location.path('/profile/' + currentTab).replace();
    }
    window.__pageproof_bridge__.seoService.set({
      title: 'main-header.profile',
      translateTitle: true,
    });
  }, []);

  const switchTab = (tab) => {
    setCurrentTab(tab);
    $location.path('/profile/' + tab).ignore();
  };

  return (
    <UserProfile
      userId={userData.userId}
      lastLogin={new Date(normaliseUTCToISO(userData.lastLogin))}
      profileTabs={profileTabs}
      currentTab={currentTab}
      switchTab={switchTab}
    />
  );
};

export default UserProfileContainer;
