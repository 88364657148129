/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CollectionProofDownloadPopover from '../../components/CollectionProofDownloadPopover';
import Popover from '../../components/Popover';
import Tooltip from '../../components/Tooltip';
import { Translation } from '../../components/Text';
import { getInitialSelectionState, mapFileForSelection, getSelectedIdsToDownload } from '../../util/proof-download-utils';
import UnstyledButton from '../../components/Button/UnstyledButton';


const CollectionProofDownloadContainer = ({ children, status, downloadProgress, collectionFiles, onDownload, proofType }) => {
  const [selection, setSelection] = useState(() => getInitialSelectionState(collectionFiles));
  const files = collectionFiles.map(file => mapFileForSelection(file, selection, downloadProgress));

  return (
    <Popover
      up
      center
      content={
        <CollectionProofDownloadPopover
          proofType={proofType}
          status={status}
          files={files}
          downloadCount={window.objectValues(selection).filter(Boolean).length}
          onSelectChange={selected => (
            setSelection(prevState => ({
              ...prevState,
              ...selected,
            }))
          )}
          allSelected={!files.some(a => !a.selected)}
          onDownload={() => onDownload(getSelectedIdsToDownload(selection))}
        />
      }
      variant="light"
      arrow
      offset={15}
    >
      {popover => (
        <Tooltip
          up
          center
          title={<Translation value="proof.utilities.download.collection.tooltip" />}
          maxWidth={150}
          disabled={popover.isVisible}
          offset={15}
        >
          <UnstyledButton
            onClick={popover.toggle}
          >
            {children}
          </UnstyledButton>
        </Tooltip>
      )}
    </Popover>
  );
};

if (process.env.NODE_ENV === 'development') {
  CollectionProofDownloadContainer.propTypes = {
    children: PropTypes.node.isRequired,
    status: PropTypes.oneOf([
      'downloading',
      'archiving',
    ]),
    downloadProgress: PropTypes.objectOf(PropTypes.number).isRequired,
    collectionFiles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    onDownload: PropTypes.func.isRequired,
    proofType: PropTypes.string.isRequired,
  };
}

export default CollectionProofDownloadContainer;
