/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import css from './InputLimitCounter.scss';

import byteLength from '../../util/byteLength';

const InputLimitCounter = ({ count, value, bytes, limit, threshold = 0.9 }) => {
  const current = (
    // eslint-disable-next-line
    typeof value === 'string'
      ? value.length
      : typeof bytes === 'string'
        ? byteLength(bytes)
        : count
  ) || 0;
  const warning = current >= (limit * threshold);
  return (
    <div
      className={classname(css.InputLimitCounter, {
        [css['InputLimitCounter--warning']]: warning,
      })}
    >
      <div className={css.InputLimitCounter__counter}>
        {current}
        {'/'}
        {limit}
      </div>
    </div>
  );
};

export default InputLimitCounter;
