/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import UnstyledButton from '../Button/UnstyledButton';
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut';
import css from './DrawingControlsOption.scss';

function DrawingControlsOption({ label, iconUrl, disabled, keyboardShortcut, onClick }) {
  useKeyboardShortcut(keyboardShortcut, onClick);

  return (
    <div className={css.DrawingControlsOption}>
      <UnstyledButton
        className={css.DrawingControlsOption__button}
        disabled={disabled}
        onClick={onClick}
      >
        <InlineSVG
          className={css.DrawingControlsOption__button__icon}
          src={iconUrl}
        />
      </UnstyledButton>
      <div className={css.DrawingControlsOption__label}>
        {label}
      </div>
    </div>
  );
}

DrawingControlsOption.propTypes = {
  label: PropTypes.node.isRequired,
  iconUrl: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  keyboardShortcut: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onClick: PropTypes.func.isRequired,
};

export default DrawingControlsOption;
