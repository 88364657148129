/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './JumpToInput.scss';
import Input from '../Input';

const JumpToInput = ({ currentValue, max, min, onChange }) => {
  const [inputValue, setInputValue] = useState(null);
  const value = inputValue === null ? currentValue : inputValue;

  const handleChange = (newValue) => {
    if (newValue === '') {
      // Allow empty input
      setInputValue('');
      // Single zero is not allowed but zero is allowed after a number. Users are allowed to type within the value range
    } else if (/^[1-9]{1}[0-9]*$/.test(newValue) && Number(newValue) <= max) {
      setInputValue(newValue);
    }
  };

  const onValueChange = () => {
    if (inputValue === '') {
      onChange(currentValue);
    } else {
      const number = parseInt(inputValue, 10);
      if (number >= min && number <= max) {
        onChange(number);
      }
    }
    setInputValue(null);
  };

  return (
    <Input
      className={css.JumpToInput}
      type="text"
      inputmode="numeric"
      min={min}
      max={max}
      style={{ width: `${(value.toString().length || 1) + 0.5}ch` }}
      value={value}
      onChange={handleChange}
      onBlur={onValueChange}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onValueChange();
        }
      }}
    />
  );
};

if (process.env.NODE_ENV !== 'production') {
  JumpToInput.propTypes = {
    currentValue: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
  };
}

export default JumpToInput;
