/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { forwardRef } from 'react';
import ReactDOM from 'react-dom';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import getSDK from './util/sdk';
import app from './util/angular-app';
import { I18nProvider } from './components/I18n/I18nProvider';

const muiTheme = getMuiTheme({
  fontFamily: "'Circular Pro', 'HelveticaNeue', 'Helvetica Neue'",
  datePicker: {
    selectColor: '#148034',
    headerColor: '#148034',
    calendarTextColor: '#148034',
    accentColor: '#148034',
  },
  timePicker: {
    headerColor: '#148034',
    calendarTextColor: '#148034',
    selectColor: '#727272',
    accentColor: '#148034',
  },
  flatButton: {
    primaryTextColor: '#138b3b',
    fontWeight: 'bold',
  },
});

const routes = {
  '/proof-setup/:proofId': {
    component: require('./containers/ProofSetupContainer/byProofId'),
    middleware: ['auth'],
    intercom: false,
  },
  '/create': {
    component: require('./containers/ProofSetupContainer'),
    middleware: ['auth'],
    intercom: false,
  },
  '/create/external': {
    component: require('./containers/ProofSetupContainer/byExternal'),
    middleware: ['auth'],
    intercom: false,
  },
  '/create/external-id': {
    component: require('./containers/ProofSetupContainer/byExternalId'),
    middleware: ['auth'],
    intercom: false,
  },
  '/create/cep': {
    component: require('./containers/ProofSetupContainer/byCEP'),
    middleware: ['auth'],
    intercom: false,
  },
  '/flags': {
    component: require('./components/Flags'),
    middleware: ['auth'],
    intercom: false,
  },
  '/profile/:tab?': {
    component: require('./containers/UserProfileContainer'),
    middleware: ['auth'],
    intercom: false,
  },
  '/team/checklists/templates': {
    component: require('./containers/ChecklistTemplatesDashboard'),
    middleware: ['auth'],
    intercom: false,
  },
  '/team/checklists/templates/:checklistTemplateId': {
    component: require('./containers/ChecklistTemplatesDashboard'),
    middleware: ['auth'],
    intercom: false,
  },
  '/workflows/templates/new': {
    component: require('./containers/NewWorkflowTemplateContainer'),
    middleware: ['auth'],
    intercom: false,
  },
  '/workflows/templates/:workflowId': {
    component: require('./containers/WorkflowTemplatePage/WorkflowTemplatePage').WorkflowTemplatePage,
    middleware: ['auth'],
    intercom: false,
  },
  '/team/proof-templates': {
    component: require('./containers/ProofTemplatesDashboard'),
    middleware: ['auth'],
    intercom: false,
  },
  '/team/proof-templates/:proofTemplateId': {
    component: require('./containers/ProofTemplatesDashboard'),
    middleware: ['auth'],
    intercom: false,
  },
  '/team/webhooks': {
    component: require('./containers/WebhooksDashboard'),
    middleware: ['auth'],
    intercom: false,
  },
  '/team/members': {
    component: require('./containers/TeamMembersDashboard'),
    middleware: ['auth'],
    intercom: false,
  },
};

const containers = {
  // Add an entry below for any containers which are needed within the app...
  WorkflowTemplateDownloadContainer: require('./containers/WorkflowTemplateDownloadContainer'),
  TagsContainer: require('./containers/Tags'),
  UniversalSearchContainer: require('./components/UniversalSearch'),
  UserAvatarContainer: require('./containers/UserAvatar'),
  DeviceDropdownContainer: require('./containers/DeviceDropdown'),
  NetworkDropdownContainer: require('./containers/NetworkDropdown'),
  WebProofContainer: require('./containers/WebProof'),
  WebProofOptionsContainer: require('./containers/WebProofOptions'),
  VideoSpeedContainer: require('./containers/VideoSpeedDropdown'),
  VideoQualityContainer: require('./containers/VideoQualityDropdown'),
  PlayerVolumeContainer: require('./containers/PlayerVolume'),
  IntegrationsContainer: require('./containers/Integrations'),
  ReportingContainer: require('./containers/Reporting'),
  BrandingSettingsContainer: require('./containers/BrandingSettings'),
  VideoScrubberContainer: require('./containers/VideoScrubber'),
  BriefInfoContainer: require('./containers/BriefInfo'),
  DashboardNavigationContainer: require('./containers/DashboardNavigation'),
  DashboardNavigationDropdownContainer: require('./containers/DashboardNavigationDropdown'),
  WorkflowDropdownContainer: require('./containers/WorkflowDropdown'),
  ProofVersionsButton: require('./components/ProofVersionsButton'),
  CollectionProofMenu: require('./components/CollectionProofMenu'),
  VideoPlayer: require('./components/VideoPlayer/VideoPlayer').VideoPlayer,
  DateTimePickerIconButton: require('./components/DateTimePickerIconButton'),
  Checkbox: require('./components/Checkbox'),
  OAuthAuthorizeContainer: require('./components/OAuthAuthorize'),
  TeamDashboardContainer: require('./containers/TeamDashboard'),
  DashboardContainer: require('./containers/Dashboard'),
  DomainAdminProfileContainer: require('./containers/DomainAdminProfile'),
  SwitchContainer: require('./components/Switch'),
  ResetLocalStorageContainer: require('./containers/ResetLocalStorage'),
  PromptExtensionContainer: require('./containers/PromptExtension'),
  UrlImportContainer: require('./containers/UrlImport'),
  ProofDownloadButtonContainer: require('./containers/ProofDownloadButton'),
  ProofScreenMobileMenu: require('./components/ProofScreenMobileMenu'),
  PreviewFileButton: require('./containers/PreviewFileButton'),
  PrintOptionsButtonContainer: require('./containers/PrintOptionsButton'),
  AwaitingEmailMessageForSafari: require('./components/AwaitingEmailMessageForSafari'),
  CommentOptionsContainer: require('./containers/CommentOptions'),
  DomainAdminDashboardNavigationContainer: require('./containers/DomainAdminDashboard'),
  DomainAdminInviteContainer: require('./containers/DomainAdminInviteTeam'),
  MobileProofPageNavigation: require('./components/MobileProofPageNavigation'),
  MobileProofHeaderCenter: require('./components/MobileProofHeaderCenter'),
  PromptUpdateContainer: require('./containers/PromptUpdate'),
  PaginationContainer: require('./containers/Pagination'),
  GroupProofsPopupContainer: require('./containers/GroupProofsPopup'),
  ShowMoreTextContainer: require('./components/Text/ShowMore'),
  CommentEvents: require('./containers/CommentEvents'),
  ProofPermissionsEvents: require('./containers/ProofPermissionsEvents'),
  ReminderContainer: require('./containers/ReminderPopUp'),
  IntegrationReferencesContainer: require('./containers/IntegrationReferencesContainer'),
  IntegrationReferenceSelectorContainer: require('./containers/IntegrationReferenceSelectorContainer'),
  LockContainer: require('./components/Lock'),
  NotificationTray: require('./components/NotificationTray'),
  NotificationSnooze: require('./components/NotificationSnooze/NotificationSnooze'),
  CollectionManageContainer: require('./containers/CollectionManage'),
  IntegrationApps: require('./components/IntegrationApps'),
  ProofPageComments: require('./containers/ProofPageComments'),
  CommentsHeader: require('./containers/CommentsHeader'),
  ModalMessages: require('./components/ModalMessages'),
  AuditSummaryPaneContainer: require('./containers/AuditSummaryPane'),
  Mark: require('./components/Comment/Mark'),
  UnifiedLoginContainer: require('./containers/UnifiedLogin'),
  UserStatusMenuContainer: require('./containers/UserStatusMenu'),
  GiftMenu: require('./components/GiftMenu'),
  NextAvatarContainer: require('./containers/NextAvatar'),
  UploadActivityTray: require('./containers/UploadActivityTray'),
  PrintActivityContainer: require('./containers/PrintActivity'),
  WorkflowStepExplanationTooltip: require('./components/WorkflowStepExplanationTooltip'),
  ProofInfoInlineTooltip: require('./containers/ProofInfoInlineTooltip'),
  TeamSettingsContainer: require('./containers/TeamSettings'),
  ThreeDots: require('./components/Comment/Options'),
  WorkflowTemplatePage: require('./containers/WorkflowTemplatePage/WorkflowTemplatePage').WorkflowTemplatePage,
  ProofInfoWorkflowSteps: require('./containers/ProofInfoWorkflowSteps'),
  DecisionButtonContainer: require('./containers/DecisionButtonContainer'),
  WorkflowUserStatus: require('./components/WorkflowUserStatus'),
  WorkflowNotVisibleOverlay: require('./components/WorkflowNotVisibleOverlay'),
  TeamInvitationContainer: require('./components/TeamInvitation'),
  SCIMSettingsContainer: require('./containers/SCIMSettings'),
  PenTool: require('./components/PenTool'),
  Walkthrough: require('./components/Walkthrough'),
  DeleteButton: require('./components/DeleteButton'),
  CustomTools: require('./components/CustomTools'),
  CommentStatusCountBar: require('./components/ModalMessages/CommentStatusCountBar'),
  UploadBriefContainer: require('./containers/UploadBrief'),
  MultiEditorForm: require('./components/MultiEditorForm'),
  UserAvatarsList: require('./components/UserAvatarsList'),
  ProofPageInfo: require('./components/ProofPageInfo'),
  ProofPageInfoDimensionsSetter: require('./components/ProofPageInfo/ProofPageInfoDimensionsSetter'),
  PrintOptionsContainer: require('./containers/PrintOptions'),
  ImportPdfCommentsContainer: require('./containers/ImportPdfComments'),
  AccountSwitcher: require('./components/AccountSwitcher'),
  CombineExternalFilesModal: require('./components/CombineExternalFilesModal'),
  PageGridZoomContainer: require('./containers/PageGridZoomContainer').PageGridZoomContainer,
  IntegrationOptionsContainer: require('./containers/IntegrationOptions'),
  ZoomControlContainer: require('./containers/ZoomControl'),
  CommentPinTooltip: require('./components/CommentPinTooltip'),
  ResumeProofSetupButton: require('./containers/ResumeProofSetupButton'),
  ProgressContainer: require('./containers/Progress'),
  GridLines: require('./components/GridLines'),
  CompareVersionsButton: require('./components/CompareVersionsButton'),
  CompareSwitchSidesTool: require('./components/CompareSwitchSidesTool'),
  NextProofTile: require('./components/NextProofTile'),
  HelpBubble: require('./components/HelpBubble'),
  ChangeDecisionButtonContainer: require('./containers/ChangeDecisionButtonContainer'),
  ConfirmBox: require('./components/ConfirmBox'),
  ProofTileFileDropper: require('./components/ProofSetup/components/ProofTileFileDropper'),
  MainMenuLanguageSwitcher: require('./components/MainMenuLanguageSwitcher'),
  MainMenuThemeSwitcher: require('./components/MainMenuThemeSwitcher'),
  ProfilePasswordIndicatorTextContainer: require('./containers/ProfilePassword/ProfilePasswordIndicatorText'),
  ModalChildrenPassthrough: require('./util/modal').ChildrenPassthrough,
  UserProfileAvatarContainer: require('./containers/UserProfileAvatarContainer'),
  ProofShareButtonContainer: require('./containers/ProofShareButtonContainer'),
  DecisionSummaryContainer: require('./containers/DecisionSummaryContainer'),
  HeaderTray: require('./components/HeaderTray'),
  InstallExtensionPromptModal: require('./components/InstallExtensionPromptModal'),
  ColorSeparationsPreview: require('./components/ColorSeparationsPreview'),
  WorkflowStepDueDate: require('./components/WorkflowStepDueDate'),
  ProofInfoTitleInput: require('./components/ProofInfoTitleInput'),
  Textarea: require('./components/Textarea'),
  ProfileAboutMe: require('./components/ProfileAboutMe/ProfileAboutMe'),
  NotificationsTabs: require('./components/NotificationsTabs'),
  NotificationToggle: require('./components/NotificationToggle/NotificationToggle'),
  ProfilePasswordAndSecurity: require('./containers/ProfilePasswordAndSecurity'),
  PageGridView: require('./containers/PageGridView'),
  Spinner: require('./components/Spinner/Spinner'),
  HeaderAvatar: require('./components/HeaderAvatar/HeaderAvatar'),
  GeneralCommentIconButtons: require('./components/Comment/GeneralCommentIconButtons'),
  FileDropperIconButton: require('./components/ProofSetup/components/FileDropperIconButton'),
  ProofCollectionButton: require('./components/ProofCollectionButton'),
  ProofChecklist: require('./components/ProofChecklist'),
  ProofChecklistMobile: require('./components/ProofChecklistMobile'),
  MandatoryDecisionThreshold: require('./components/MandatoryDecisionThreshold'),
  DrawingControls: require('./components/DrawingControls'),
  InfoPaneWorkflowOptions: require('./containers/InfoPaneWorkflowOptions'),
  ExitModeButton: require('./components/Button/ExitModeButton'),
  CommentsPane: require('./containers/CommentsPane'),
  ColorDotWrapper: require('./containers/ColorDotWrapper'),
  BarcodeScanner: require('./containers/BarcodeScanner/BarcodeScanner'),
  LegacyUserSuggestionsInput: require('./containers/LegacyUserSuggestionsInput'),
  CollectionManageBackButton: require('./containers/CollectionManage/CollectionManageBackButton'),
  JumpToInput: require('./components/JumpToInput'),
  SmartCompareDiffSettings: require('./components/SmartCompareDiffSettings').SmartCompareDiffSettings,
  Truncate: require('./components/Truncate'),
  AddChecklistManagementOption: require('./containers/AddChecklistManagementOption'),
};

export function buildWrapper(InnerComponent) {
  const Wrapper = forwardRef((props, ref) => (
    <DndProvider backend={HTML5Backend}>
      <MuiThemeProvider muiTheme={muiTheme}>
        <I18nProvider>
          <InnerComponent
            ref={ref}
            {...props}
          />
        </I18nProvider>
      </MuiThemeProvider>
    </DndProvider>
  ));

  Wrapper.displayName = `Wrapper(${InnerComponent.displayName || InnerComponent.name})`;
  return Wrapper;
}

Object.keys(containers).forEach(name => (
  app.value(name, buildWrapper(containers[name].default || containers[name]))
));

const sdk = getSDK();
app.value('sdk', sdk);
window.__pageproof_bridge__.sdk = sdk;

window.__pageproof_quark__ = window.__pageproof_quark__ || {};
window.__pageproof_quark__.session = require('../../shared/session');
window.__pageproof_quark__.sdk = require('@pageproof/sdk');
window.__pageproof_quark__.cookies = require('../../shared/cookies').default;
window.__pageproof_quark__.accounts = require('../../shared/accounts');
window.__pageproof_quark__.defaultFlagValues = require('./components/Flags').defaultFlagValues;
window.__pageproof_quark__.features = require('./features');
window.__pageproof_quark__.walkthrough = require('./services/walkthrough');
window.__pageproof_quark__.timing = require('./services/timing');
window.__pageproof_quark__.makeUnstableBackendRequest = require('./util/unstable-backend-request').makeUnstableBackendRequest;

window.__pageproof_quark__.proofSetup = {
  addFile: require('./components/ProofSetup/utils/addFile'),
  requestProofSetup: require('./components/ProofSetup/utils/requestProofSetup').default,
};

window.__pageproof_quark__.colorPreferences = {
  getPreferencePinColorMappingObject: require('./util/user-color-preference').getPreferencePinColorMappingObject,
};

window.__pageproof_quark__.translation = {
  createReactTranslationElement: require('./util/create-react-translation-element').createReactTranslationElement,
  text: require('./components/Text/Translation').default.text,
};

window.__pageproof_quark__.comments = {
  getCommentDeviceCategory: require('./components/Comment/utils/getCommentDeviceCategory').default,
  getCommentWebPagePath: require('./components/Comment/utils/getCommentWebPagePath').default,
};

window.__pageproof_quark__.troubleshooting = require('./features/troubleshooting');

window.__pageproof_quark__.webProofUrls = require('./util/webProofUrls');

window.__pageproof_quark__.pixelmatch = require('./util/pixelmatch');
window.__pageproof_quark__.smartCompare = require('./features/smartCompare');

window.__pageproof_next_routes__ = window.__pageproof_next_routes__ || {};
Object.keys(routes).forEach((route, index) => {
  const valueId = 'nextRoute' + index;
  const { component, ...data } = routes[route];

  app.value(valueId, buildWrapper(component.default || component));

  window.__pageproof_next_routes__[route] = {
    template: `<react-component name="${valueId}" props="ctrl.props"></react-component>`,
    controller: ['$location', '$routeParams', function Controller($location, $routeParams) {
      this.props = {
        initialLocation: {
          path: $location.path(),
          search: $location.search(),
        },
        initialRouteParams: {
          ...$routeParams,
        },
      };
    }],
    controllerAs: 'ctrl',
    data,
  };
});

window.__pageproof_quark__.renderComponent = (rootElement, componentName, componentProps) => {
  const Component = containers[componentName].default || containers[componentName];
  const Wrapper = buildWrapper(Component);

  let props = componentProps;
  ReactDOM.render(<Wrapper {...props} />, rootElement);

  return {
    update(updatedComponentProps = props) {
      props = updatedComponentProps;
      ReactDOM.render(<Wrapper {...props} />, rootElement);
    },
    unmount() {
      ReactDOM.unmountComponentAtNode(rootElement);
    },
  };
};

require('./preload');

export default containers;
