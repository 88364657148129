/* eslint-disable */

import React, { Fragment } from 'react';
import classname from 'classname';
import css from './ProofCarousel.scss';

import UploadProgressCircle from './UploadProgressCircle';
import SetupItem from './SetupItem';
import IsScrolled from './IsScrolled';
import Tooltip from '../../Tooltip/Tooltip';
import useDarkMode from '../../../hooks/useDarkMode';
import { canRetry, retry } from '../utils/upload';
import HeaderBanner from './HeaderBanner';
import SelectFileOptions from './SelectFileOptions';

const ProofCarousel = ({
  proofs,
  shared,
  parameters,
  userPreferences,
  currentIndex,
  onCurrentIndexChange,
  onProofFileRemove,
  onProofRemove,
}) => {
  const [isDarkMode] = useDarkMode();
  const isSingleProof = proofs.length === 1;
  const isUpdateProof = !!parameters.updateProof;
  const isBrief = parameters.proofType === 'brief';

  return (
    <IsScrolled
      leftClassName={css['ProofCarousel--leftShadow']}
      rightClassName={css['ProofCarousel--rightShadow']}
    >
      {({ ref, className }) => (
        <div className={classname(css.ProofCarousel, className)}>
          <div ref={ref} className={css.ProofCarousel__proofs}>
            <div style={{ paddingLeft: 2, height: 1 }} />
            {proofs.map((proof, index) => {
              const isCurrentIndex = currentIndex === index;
              const isUploading = proof.file && proof.file.status === 'uploading';
              return (
                <Tooltip
                  key={proof._id}
                  center
                  down
                  delay={200}
                  variant={isDarkMode ? 'dark' : 'light'}
                  padding={false}
                  arrow
                  offset={12}
                  title={
                    <SetupItem
                      icon={<SetupItem.ProofIcon proof={proof} size={46} />}
                      title={<SetupItem.ProofName proof={proof} />}
                      description={<SetupItem.ProofDescription proof={proof} />}
                      canInteract={false}
                      inTooltip={true}
                    />
                  }
                  disabled={isSingleProof}
                >
                  <SetupItem
                    icon={
                      (proof.file && proof.file.status !== 'error')
                        ? (
                          <SetupItem.ProofIcon
                            proof={proof}
                            size={46}
                          />
                        )
                        : (
                          <SelectFileOptions
                            direction="down"
                            initialize={proof._id}
                            parameters={parameters}
                            multiple={false}
                          >
                            <HeaderBanner.IconButton
                              variant={proof.file ? 'red' : 'green'}
                              size="medium"
                              src="/img/icons/file-dropper.svg"
                              translateY={2}
                              active
                            />
                          </SelectFileOptions>
                        )
                    }
                    title={<SetupItem.ProofName proof={proof} />}
                    description={<SetupItem.ProofDescription proof={proof} />}
                    isCompact={!isSingleProof}
                    isEdited={proof._isEdited && proofs.length > 1}
                    isSelected={isCurrentIndex}
                    onClick={() => onCurrentIndexChange(index)}
                    className={isCurrentIndex && css.ProofCarousel__selectedProof}
                    editedReason={
                      <SetupItem.ProofEditedReason
                        proof={proof}
                        shared={shared}
                        parameters={parameters}
                        userPreferences={userPreferences}
                      />
                    }
                    options={
                      <Fragment>
                        {canRetry(proof.file) && (
                          <SetupItem.Option
                            visibility="on-interaction"
                            icon="/img/content/proof/icons/video/loop.svg"
                            onClick={() => retry(proof._id)}
                          />
                        )}
                        {isUploading && (
                          <SetupItem.Option
                            icon={
                              isSingleProof
                                ? <UploadProgressCircle id={proof._id} />
                                : (
                                  <UploadProgressCircle
                                    id={proof._id}
                                    trackColor="#444"
                                    progressColor="white"
                                    size={14}
                                  />
                                )
                            }
                            visibility="not-on-interaction"
                          />
                        )}
                        {((proofs.length > 1 || proof.file) && !isBrief) && (
                          <SetupItem.Option
                            icon="/img/interface/menu-close-icon.svg"
                            visibility={isCurrentIndex ? 'always' : 'on-interaction'}
                            onClick={() => {
                              if (proof.file) {
                                onProofFileRemove(proof._id);
                              } else {
                                onProofRemove(proof._id);
                              }
                            }}
                          />
                        )}
                      </Fragment>
                    }
                  />
                </Tooltip>
              );
            })}
            <div style={{ paddingRight: 10, height: 1 }} />
          </div>
        </div>
      )}
    </IsScrolled>
  );
};

export default ProofCarousel;
