/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Tooltip from '../Tooltip';
import { Translation } from '../Text';
import css from './NotificationToggle.scss';

const NotificationToggle = ({ onToggle, isRead }) => (
  <Tooltip
    maxWidth={150}
    delay={800}
    up
    center
    title={isRead
      ? <Translation value="notification.toggle.mark-as-unread" />
      : <Translation value="notification.toggle.mark-as-read" />
    }
  >
    <button
      onClick={onToggle}
      type="button"
      className={classname(css.NotificationToggle__button, {
        [css['NotificationToggle__button--isRead']]: isRead,
      })}
    />
  </Tooltip>
);


NotificationToggle.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isRead: PropTypes.bool.isRequired,
};

export default NotificationToggle;
