/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { Translation, TranslatedProps } from '../Text';
import Switch from '../Switch';
import Input from '../Input';
import Tooltip from '../Tooltip';
import {Button} from '../Button';
import useDarkMode from '../../hooks/useDarkMode';

function Integration({
  name,
  tooltip,
  logo,
  logoDark,
  enabled,
  connectionString,
  onToggle,
  onChange,
  onBlur,
  learnHowLink,
  inputs,
  connectToZapier,
}) {
  const [isDarkMode] = useDarkMode();

  return (
    <div className="Integration">
      <Switch
        value={enabled}
        onChange={onToggle}
      />
      <Tooltip title={tooltip} up center>
        <TranslatedProps alt={{value: 'domain-admin.integrations.alt.logo', params: {alt: name}}}>
          <img
            className={classname('Integration__logo', {
              'Integration__logo--disabled': !enabled,
            })}
            src={isDarkMode ? logoDark : logo}
            alt=""
            height="30"
          />
        </TranslatedProps>
      </Tooltip>
      <div className={enabled && !inputs[0].type ? 'Integration__button-wrap' : 'Integration__input'}>
        {(enabled && !inputs[0].type) && (
          <div className="Integration__button-holder">
            <Button
              variant="primary"
              onClick={connectToZapier}
              label={<Translation value="widget-zapier-manage" />}
            />
          </div>
        )}
        {(enabled && inputs[0].type) &&
          inputs.map((input, index) => (
            <div key={index}>
              <label><Translation value="domain-admin.integrations.connection" /></label>
              <TranslatedProps placeholder={input.placeholderTranslationKey}>
                <Input
                  autoFocus={!connectionString}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={connectionString}
                  name={name}
                />
              </TranslatedProps>
            </div>
          ))
        }
        {learnHowLink && (
          <a
            className={inputs[0].type ? 'msg-note' : 'msg-note msg-note__note-with-button'}
            href={learnHowLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Translation value="domain-admin.integrations.learn-how" />
          </a>
        )}
      </div>
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  Integration.propTypes = {
    tooltip: PropTypes.objectOf(PropTypes.any),
    logo: PropTypes.string.isRequired,
    logoDark: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    connectionString: PropTypes.string,
    onToggle: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    learnHowLink: PropTypes.string,
    inputs: PropTypes.array.isRequired, // eslint-disable-line
    connectToZapier: PropTypes.func,
  };
}
export default Integration;
