/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-param-reassign, no-plusplus, arrow-body-style, default-case, indent, prefer-spread, consistent-return */

import React, { Fragment, useState, useCallback, useRef, useEffect, forwardRef } from 'react';
import classname from 'classname';
import Portal from '../components/Portal';
import { watchRect } from './watchRect';

let stitchEmbedId = 0;

const resetPositionStyles = {
  top: '',
  left: '',
  right: '100%',
  bottom: '100%',
  width: '',
  height: '',
};

function formatProps(props) {
  props = Object.assign({}, props);
  Object.keys(props).forEach((key) => {
    if (typeof props[key] === 'function') {
      props[key] = '__stitch_embed_callback__';
    }
  });
  return props;
}

class StitchEmbedController {
  iframeRef = React.createRef();

  iframeHeight = 0;

  constructor(name, initialProps, settings) {
    this.isMounted = false;

    this.id = 'stitch_embed_id_' + stitchEmbedId++;
    this.props = initialProps;
    this.settings = settings;
    this.isSkinTooltipVisible = false;

    this.iframeUrl = '/embed/' + name + '#' + new URLSearchParams({
      id: this.id,
      initialProps: JSON.stringify(formatProps(initialProps)),
      embedSettings: JSON.stringify(settings),
    });
  }

  didUpdateProps() {
    if (this.isMounted) {
      this.iframeRef.current.contentWindow.postMessage({
        id: this.id,
        type: 'StitchEmbedUpdateProps',
        props: formatProps(this.props),
      });
    }
  }

  didMount() {
    this.isMounted = true;
    this.didUpdateProps();
  }

  shouldResize(height) {
    if (this.settings.observeHeight) {
      this.iframeHeight = height;
      this.iframeRef.current.style.height = height + 'px';
      this.onResize(height);
    }
  }

  // eslint-disable-next-line no-unused-vars
  onResize = (height) => {
    // used by the eager renderer to hook into the height
  }

  onDialogSetOpen = (isOpen) => {
    console.log('onDialogSetOpen', isOpen);
  }

  onCallback(name, args) {
    this.props[name].apply(this.props, args);
  }

  updateProps(props) {
    this.props = props;
    this.didUpdateProps();
  }

  onMessage = (event) => {
    switch (event.data.type) {
      case 'StitchEmbedMounted': {
        this.didMount();
        break;
      }
      case 'StitchEmbedResize': {
        this.shouldResize(event.data.height);
        break;
      }
      case 'StitchEmbedDialogSetOpen': {
        this.onDialogSetOpen(event.data.isOpen);
        break;
      }
      case 'StitchEmbedCallback': {
        this.onCallback(event.data.prop, event.data.args);
        break;
      }
      case 'SkinTooltipVisible': {
        this.isSkinTooltipVisible = event.data.isVisible;
        this.onResize(this.iframeHeight);
        break;
      }
      case 'StitchEmbedKeyboardEvent': {
        const { type, key, modifiers } = event.data.event;
        window.Mousetrap.handleKey(key, modifiers, {
          type,
          target: this.iframeRef.current,
          preventDefault: () => {},
        });
        break;
      }
    }
  };

  register() {
    window.addEventListener('message', this.onMessage);
    return () => {
      window.removeEventListener('message', this.onMessage);
    };
  }
}

/**
 * Creates a react component that embeds a Stitch embedded page.
 *
 * @param {string} name The name of the embedded page.
 * @param {{ iframeProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>; observeHeight?: boolean }} [settings] The stitch embed settings.
 * @returns React.FC
 */
export const createStitchEmbed = (name, settingsOrSettingsRef) => {
  const settingsRef = typeof settingsOrSettingsRef.current !== 'undefined'
    ? settingsOrSettingsRef
    : { current: settingsOrSettingsRef || {} };

  const StitchIframe = forwardRef((props, ref) => {
    const { iframeProps } = settingsRef.current;
    return (
      <iframe
        {...iframeProps}
        {...props}
        className={classname('stitch-embed', iframeProps && iframeProps.className, props.className)}
        style={{ ...(iframeProps && iframeProps.style), ...props.style }}
        src={props.src}
        ref={ref}
      />
    );
  });
  const StitchEmbedComponent = (props) => {
    const { iframeProps, ...settings } = settingsRef.current;
    const [controller] = useState(() => new StitchEmbedController(name, props, settings));
    controller.updateProps(props);
    useEffect(() => controller.register(), []);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    controller.onDialogSetOpen = setIsDialogOpen;

    return (
      <Fragment>
        {isDialogOpen && (
          <Portal>
            <div className="stitch-dialog-header-cover" />
          </Portal>
        )}
        <StitchIframe
          ref={controller.iframeRef}
          src={controller.iframeUrl}
        />
      </Fragment>
    );
  };
  StitchEmbedComponent.Eager = ({ onBeforeEmbedUpdatesPosition, ...props }) => {
    const { iframeProps: _, ...initialSettings } = settingsRef.current;
    const [controller] = useState(() => new StitchEmbedController(name, props, initialSettings));
    controller.updateProps(props);
    useEffect(() => controller.register(), []);

    const onBeforeEmbedUpdatesPositionRef = useRef();
    onBeforeEmbedUpdatesPositionRef.current = onBeforeEmbedUpdatesPosition;

    const placementStateRef = useRef();
    const acceptPlacement = (placementElement) => {
      if (placementStateRef.current) {
        return; // Placement rendered in two places, ignore second placement.
      }
      let isInitialPosition = true;
      const iframeElement = controller.iframeRef.current;
      const updateEmbedSize = ({ top, left, width, height }) => {
        const { iframeProps, ...settings } = settingsRef.current;

        const finalWidth = (iframeProps.style && iframeProps.style.width) || width;
        const finalHeight = settings.observeHeight ? controller.iframeHeight : height;
        const clipPath = (iframeProps.style && iframeProps.style.clipPath) || '';

        let embedStyles = {
          width: typeof finalWidth === 'number' ? `${Math.round(finalWidth)}px` : finalWidth,
          height: Math.round(finalHeight) + 'px',
          top: Math.round(top) + 'px',
          left: Math.round(left) + 'px',
          transform: `scaleX(${width / finalWidth}) scaleY(${height / finalHeight})`,
          transformOrigin: 'top left',
          willChange: 'transform, top, left, width, height',
          right: 'unset',
          bottom: 'unset',
          clipPath: controller.isSkinTooltipVisible ? '' : clipPath,
        };
        if (onBeforeEmbedUpdatesPositionRef.current) {
          const value = onBeforeEmbedUpdatesPositionRef.current({ inlineStyles: embedStyles, isInitialPosition });
          if (value && value.inlineStyles) {
            embedStyles = value.inlineStyles;
          }
        }
        Object.assign(iframeElement.style, embedStyles);
        isInitialPosition = false;
      };
      // When the embed page resizes, update the height of the placement element.
      controller.onResize = (height) => {
        if (settingsRef.current.observeHeight) {
          placementElement.style.height = height + 'px';
          updateEmbedSize(placementElement.getBoundingClientRect());
        }
      };
      if (settingsRef.current.observeHeight) {
        placementElement.style.height = controller.iframeHeight + 'px';
        iframeElement.style.height = controller.iframeHeight + 'px';
      }
      // When the placement element resizes, update the embed element size.
      const unwatchRect = watchRect(placementElement, updateEmbedSize);
      placementStateRef.current = placementElement;
      return () => {
        placementStateRef.current = null;
        unwatchRect();
        controller.onResize = () => {};
        Object.assign(iframeElement.style, resetPositionStyles);
      };
    };

    const Placement = useCallback((placementProps) => {
      const ref = useRef();
      useEffect(() => acceptPlacement(ref.current), []);
      return <div {...placementProps} ref={ref} />;
    }, []);

    return (
      <Fragment>
        <Portal>
          <StitchIframe
            ref={controller.iframeRef}
            src={controller.iframeUrl}
            className={classname('stitch-embed-eager', props.className)}
          />
        </Portal>
        {props.children(Placement)}
      </Fragment>
    );
  };
  return StitchEmbedComponent;
};
