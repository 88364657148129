/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Translation } from '../../components/Text';
import { walkthroughIds } from './walkthroughIds';
import { proofSetupWalkthrough } from './proofSetupWalkthrough';
import { proofEditorWalkthrough } from './proofEditorWalkthrough';

const USE_RED_PEN_STEP = {
  hooks: ['red-pen-tool'],
  tooltipDirections: {
    middle: true,
    right: true,
  },
  // This is commented out until a video is provided by Gemma
  // videoSrc: '/img/walkthrough/add-comment.mp4',
  heading: <Translation value="walk-through.use-red-pen-step.heading" />,
  description: <Translation value="walk-through.use-red-pen-step.description" />,
};

const LEAVE_DECISION_STEP = {
  hooks: ['proof-decision-button'],
  tooltipDirections: {
    up: true,
    center: true,
  },
  heading: <Translation value="walk-through.leave-decision-step.heading" />,
  description: <Translation value="walk-through.leave-decision-step.description" />,
};

const NAVIGATE_PROOF_PAGES_STEP = {
  hooks: ['proof-page-navigation'],
  tooltipDirections: {
    up: true,
    center: true,
  },
  styles: {
    borderRadius: '5px',
  },
  heading: <Translation value="walk-through.navigate-proof-pages-step.heading" />,
  description: <Translation value="walk-through.navigate-proof-pages-step.description" />,
};

const PLAY_PAUSE_VIDEO_STEP = {
  hooks: ['play-pause-video'],
  tooltipDirections: {
    up: true,
    center: true,
  },
  styles: {
    borderRadius: '5px',
  },
  heading: <Translation value="walk-through.play-pause-video-step.heading" />,
  description: <Translation value="walk-through.play-pause-video-step.description" />,
};

const PLAY_PAUSE_AUDIO_STEP = {
  hooks: ['play-pause-audio'],
  tooltipDirections: {
    up: true,
    center: true,
  },
  styles: {
    borderRadius: '5px',
  },
  heading: <Translation value="walk-through.play-pause-audio-step.heading" />,
  description: <Translation value="walk-through.play-pause-audio-step.description" />,
};

const PLAY_PAUSE_GIF_STEP = {
  hooks: ['play-pause-gif'],
  tooltipDirections: {
    up: true,
    center: true,
  },
  styles: {
    borderRadius: '5px',
  },
  heading: <Translation value="walk-through.play-pause-gif-step.heading" />,
  description: <Translation value="walk-through.play-pause-gif-step.description" />,
};

const PP_BIRTHDAY_2024_STEP = {
  href: 'https://go.pageproof.com/pageproof-birthday',
  hooks: ['w-pp-birthday-2024'],
  tooltipDirections: {
    down: true,
    center: true,
  },
  posterSrc: 'https://static-assets.pageproof.com/seasonal-events/PageProof-birthday-2023/PageProof-birthday-balloons.png',
  videoSrc: 'https://static-assets.pageproof.com/seasonal-events/PageProof-birthday-2023/PageProof-birthday-features-release-is-here.mp4',
  styles: {
    borderRadius: '5px',
  },
  heading: 'A special birthday release',
  description: 'Celebrate our birthday with us – a special birthday release of exciting new features. Click the icon to learn more.',
};

export const WALKTHROUGHS = [
  {
    id: walkthroughIds.reviewingBasics,
    canDisplay: () => window.location.href.match(/\/proof\/[^/.]*\/p/i),
    requiredSteps: [USE_RED_PEN_STEP, LEAVE_DECISION_STEP],
    steps: [
      [USE_RED_PEN_STEP],
      [NAVIGATE_PROOF_PAGES_STEP, PLAY_PAUSE_VIDEO_STEP, PLAY_PAUSE_GIF_STEP, PLAY_PAUSE_AUDIO_STEP],
      [LEAVE_DECISION_STEP],
    ],
    // In the future if another step is added here and as its own workflow, mark all of them complete when completing this one
    includedWalkthroughIds: [walkthroughIds.reviewingBasics],
  },
  proofSetupWalkthrough,
  proofEditorWalkthrough,
  {
    id: walkthroughIds.ppbirthday2024,
    requiredSteps: [PP_BIRTHDAY_2024_STEP],
    canDisplay: () => new Date() < new Date('2024/06/30') && !window.location.href.match(/\/create/i),
    steps: [
      [PP_BIRTHDAY_2024_STEP],
    ],
    includedWalkthroughIds: [walkthroughIds.ppbirthday2024],
  },
];

export default WALKTHROUGHS;
