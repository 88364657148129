/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState } from 'react';
import Button from '../../Button/Button';
import UnstyledButton from '../../Button/UnstyledButton';
import ButtonGroup from '../../ButtonGroup';
import Reveal from '../../Reveal';
import css from './PromptActivate.scss';
import Translation from '../../Text/Translation';
import Delay from '../../Delay';
import Message from './Message';
import OtpInputField from '../../OtpInputField';
import { logout } from '../../../features/logout';

const PromptActivate = ({
  loading,
  email,
  onResendActivationCode,
  onActivationCode,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [activationCode, setActivationCode] = useState('');
  const [messageTranslationKey, setMessageTranslationKey] = useState('');

  const verifyCode = (code) => {
    setIsDisabled(true);
    setMessageTranslationKey('');

    onActivationCode(code)
      .catch((err) => {
        const status = err && err.response && err.response.data && err.response.data.ResponseStatus;
        if (status === 'ERROR_USER_EXPIRED_ACTIVATION_CODE') {
          setMessageTranslationKey('activate.error.expired-code');
        } else {
          setMessageTranslationKey('activate.error.invalid-code');
          setActivationCode('');
          setIsDisabled(false);
        }
      });
  };

  const onCodeInput = (input) => {
    const code = input.toUpperCase();
    setActivationCode(code);

    if (code.length === 6) {
      verifyCode(code);
    }
  };

  const onRequestNewCode = () => {
    setIsDisabled(false);
    setActivationCode('');
    setMessageTranslationKey('');
    onResendActivationCode();
  };

  return (
    <Fragment>
      <Message
        translations={[
          'activate.title',
          'activate.notice',
          'activate.notice.junk-mail',
          'activate.incorrect-email',
        ]}
        params={{
          email: <a href={`mailto:${email}`}>{email}</a>,
          here: (
            <a
              href="/logout"
              onClick={(event) => {
                event.preventDefault();
                logout().finally(() => window.location.reload());
              }}
            >
              <Translation value="activate.incorrect-email.here" />
            </a>
          ),
        }}
        minimal
      />
      <OtpInputField
        value={activationCode}
        onChange={onCodeInput}
        isDisabled={isDisabled}
        autoFocus
      />
      <Reveal
        align="bottom"
        duration={300}
        visible={messageTranslationKey.length}
      >
        <div>
          <div style={{ height: 20 }} />
          <Message
            translation={messageTranslationKey}
            params={{
              here: (
                <UnstyledButton
                  className={css.PromptActivate__hereButton}
                  onClick={onRequestNewCode}
                >
                  <Translation value="activate.incorrect-email.here" />
                </UnstyledButton>
              ),
            }}
          />
        </div>
      </Reveal>
      {!messageTranslationKey.length &&
        <Delay ms={10000}>
          {complete => (
            <Reveal
              visible={complete && !isDisabled}
              align="bottom"
            >
              <div style={{ overflow: 'hidden' }}>
                <div style={{ height: 30 }} />
                <ButtonGroup align="center">
                  <Button
                    variant="text"
                    label={<Translation value="activate.message.invalid-token.button" />}
                    onClick={() => {
                      onResendActivationCode()
                        .then(() => {
                          setMessageTranslationKey('activate.notice.email-sent');
                        });
                    }}
                    disabled={loading}
                  />
                </ButtonGroup>
              </div>
            </Reveal>
          )}
        </Delay>
      }
    </Fragment>
  );
};

export default PromptActivate;
