/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import css from './IntegrationApps.scss';
import Tooltip from '../Tooltip';
import getIntegrationFileUrl from '../../util/integration-service-static-files';
import { TranslatedProps } from '../Text';
import useDarkMode from '../../hooks/useDarkMode';

const DEFAULT_LABEL_COLOR = '#138B3B';

const Logo = ({ tooltip, integrationKey, label }) => {
  const [isDarkMode] = useDarkMode();

  return (
    <Tooltip
      title={tooltip}
      disabled={!tooltip}
      up
      center
      delay={500}
      offset={8}
    >
      <span className={css.Logo}>
        <TranslatedProps alt={{ value: 'icon.alt', params: { name: label } }}>
          <img
            src={getIntegrationFileUrl('logo-horizontal', integrationKey, isDarkMode ? ['dark'] : undefined)}
            alt=""
          />
        </TranslatedProps>
      </span>
    </Tooltip>
  );
};

const AppTile = ({ label, configuration, appLabel, tooltip, actions, integrationKey, animation, openIntegrationOptions }) => {
  const baseClassNames = classname(css.Tile, css[`Tile--${animation}`]);

  if (!configuration || !configuration.enabled) {
    if (configuration && configuration.enableType === 'restricted') {
      return (
        <div className={classname(baseClassNames, css['Tile--restricted'])}>
          <Logo
            integrationKey={integrationKey}
            label={label}
            tooltip={configuration.enableMessage}
          />
        </div>
      );
    }

    return (
      <button
        type="button"
        className={classname(baseClassNames, css['Tile--clickable'])}
        onClick={() => actions.enable(integrationKey)}
      >
        <Logo
          integrationKey={integrationKey}
          label={label}
          tooltip={tooltip}
        />
        {appLabel && appLabel.label &&
          <div
            className={css.Tile__label}
            style={{ backgroundColor: appLabel.color || DEFAULT_LABEL_COLOR }}
          >
            {appLabel.label}
          </div>
        }
      </button>
    );
  }

  return (
    <div className={baseClassNames}>
      <Logo
        integrationKey={integrationKey}
        label={label}
        tooltip={tooltip}
      />
      <div className={css.Options}>
        {appLabel &&
          <div
            className={css.Tile__label}
            style={{ backgroundColor: appLabel.color || DEFAULT_LABEL_COLOR }}
          >
            {appLabel.label}
          </div>
        }
        {configuration.options &&
          <button
            type="button"
            className={classname(css.Options__option, css['Options__option--configuration'])}
            onClick={openIntegrationOptions}
          >
            <InlineSVG
              className={css.Options__option__icon}
              src="/img/interface/settings-no-padding.svg"
            />
          </button>
        }
        <button
          type="button"
          className={classname(css.Options__option, css['Options__option--red'])}
          onClick={() => actions.disable(integrationKey)}
        >
          <InlineSVG
            className={css.Options__option__icon}
            src="/img/content/proof/icons/delete.svg"
          />
        </button>
      </div>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Logo.propTypes = {
    tooltip: PropTypes.node,
    integrationKey: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };

  AppTile.propTypes = {
    tooltip: PropTypes.node,
    actions: PropTypes.objectOf(PropTypes.any).isRequired,
    integrationKey: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    configuration: PropTypes.objectOf(PropTypes.any),
    appLabel: PropTypes.objectOf(PropTypes.any),
    animation: PropTypes.oneOf(['emphasis', 'subtle']),
    openIntegrationOptions: PropTypes.func,
  };
}

export default AppTile;
