/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import { InlineSVG } from '@jmshal/react-inline-svg';
import Popover from '../../components/Popover';
import { Option, OptionList } from '../../components/PopupMenu';
import UnstyledButton from '../../components/Button/UnstyledButton';
import Truncate from '../../components/Truncate';
import css from './PrintActivityTray.scss';
import ProgressCircle from '../../components/ProgressCircle';
import { Translation } from '../../components/Text';

const PrintProgressStatus = ({ progress }) => {
  if (progress === 100) {
    return null;
  }

  if (progress === 0) {
    return (
      <span className={css.PrintActivityTray__queued}>
        <Translation value="print-options.queued" />
      </span>
    );
  }

  return (
    <div className={css.PrintActivityTray__progress}>
      <ProgressCircle
        size={20}
        width={3}
        color="white"
        trackColor="rgba(255, 255, 255, .2)"
        value={progress}
      />
    </div>
  );
};

const PrintActivityTray = ({ jobs }) => (
  <Popover
    content={
      <div>
        <OptionList
          style={{
            overflowY: 'auto',
            maxHeight: 400,
          }}
        >
          {jobs.map(({ id, name, progress }) => (
            <Option
              key={id}
              label={
                <Fragment>
                  <PrintProgressStatus progress={progress} />
                  <Truncate maxWidth={360}>{name}</Truncate>
                </Fragment>
              }
            />
          ))}
        </OptionList>
      </div>
    }
    down
    center
    arrow
    offset={15}
  >
    <UnstyledButton
      className={css.PrintActivityTray}
    >
      <div className={css.PrintActivityTray__count}>
        <span>{jobs.length}</span>
      </div>
      <InlineSVG
        src="img/interface/print.svg"
        width="22"
        height="22"
        fill="#138b3b"
      />
    </UnstyledButton>
  </Popover>
);

export default PrintActivityTray;
