/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { InlineSVG } from '@jmshal/react-inline-svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TimePicker from '../TimePicker';
import DefaultReminderPreference from '../DefaultReminderPreference';
import FormControl, { FormControlContext } from '../FormControl';
import Input from '../Input';
import PinColorPreferences from '../PinColorPreferences';
import { ProfileDropdown } from '../Profile';
import { Translation, withTranslations } from '../Text';
import Textarea from '../Textarea';
import { S_BREAK_MXX } from '../../util/constants';
import PageSubheading from '../Page/Subheading';
import css from './ProfileSettingsStyles';

class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: props.dueTime || '',
    };
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dueTime !== this.state.time) {
      this.setState({
        time: nextProps.dueTime,
      });
    }
  }

  updateDueTime = (time, format) => {
    const timeVal = this.state.time;
    if (format && timeVal && typeof timeVal === 'string') {
      const formattedTime = moment(timeVal, 'hh:mm a');
      this.props.onUpdateDueTime(formattedTime);
    } else if (format && timeVal === '') {
      this.props.onUpdateDueTime(timeVal);
    } else {
      this.setState({
        time,
      });
    }
  }

  render() {
    const {
      onUpdateDueDate,
      onUpdateDueTime,
      onUpdateMessageToReviewers,
      onUpdateSubmitCommentShortcut,
      dueDate,
      messageToReviewers,
      submitCommentShortcut,
    } = this.props;
    const { time } = this.state;

    return (
      <div className={css.ProfileSettings}>
        <FormControlContext.Provider value={{ minWidthBeforeCompact: S_BREAK_MXX }}>
          <PageSubheading
            title={<Translation value="profile.proof-settings" />}
            description={<Translation value="profile.settings.description" />}
          />
          <br />
          <div>
            <ProfileDropdown
              label={<Translation value="profile.settings.default-due-date.label" />}
              options={[
                { value: '0bd', label: Translation.text('profile.settings.business-today') },
                { value: '1bd', label: Translation.text('profile.settings.business-day') },
                { value: '2bd', label: Translation.text('profile.settings.business-days', { days: 2 }) },
                { value: '3bd', label: Translation.text('profile.settings.business-days', { days: 3 }) },
                { value: '4bd', label: Translation.text('profile.settings.business-days', { days: 4 }) },
                { value: '5bd', label: Translation.text('profile.settings.business-days', { days: 5 }) },
              ]}
              placeholder={<Translation value="profile.settings.default-due-date.placeholder" />}
              onClick={days => onUpdateDueDate(days)}
              selected={dueDate}
            />
          </div>
          <FormControl label={<Translation value="profile.settings.default-due-time.label" />}>
            <div>
              <div className={css.ProfileSettings__iconHolder}>
                <Input
                  type="text"
                  value={time}
                  maxLength={7}
                  onChange={val => this.updateDueTime(val)}
                  onBlur={() => this.updateDueTime(null, true)}
                />
              </div>
              <div className={css.ProfileSettings__calendarIcon}>
                <TimePicker
                  name="profileDueTime"
                  onChange={(event, val) => onUpdateDueTime(moment(val))}
                  minutesStep={15}
                  textFieldStyle={{ width: '40px', zIndex: 1, borderBottom: 'none', cursor: 'pointer', opacity: '0' }}
                />
                <InlineSVG
                  src="img/interface/time-icon.svg"
                  width={23}
                  height={23}
                />
              </div>
            </div>
          </FormControl>
          <FormControl label={<Translation value="profile.settings.reminder.label" />}>
            <DefaultReminderPreference />
          </FormControl>
          <FormControl label={<Translation value="profile.settings.reviewer-message.label" />}>
            <Textarea
              defaultValue={messageToReviewers}
              onBlur={event => onUpdateMessageToReviewers(event.target.value)}
            />
          </FormControl>

          <div className={css.ProfileSettings__header}>
            <Translation value="profile.general-settings" />
          </div>
          <FormControl
            fieldOutsideLabel
            label={<Translation value="profile.settings.pin-colors" />}
          >
            <PinColorPreferences />
          </FormControl>
          <ProfileDropdown
            label={<Translation value="profile.settings.submit-comment-shortcut.label" />}
            options={[
              { value: 'shift+enter', label: Translation.text('profile.settings.submit-comment-shortcut.shift-enter') },
              { value: 'enter', label: Translation.text('profile.settings.submit-comment-shortcut.enter') },
            ]}
            onClick={onUpdateSubmitCommentShortcut}
            selected={submitCommentShortcut}
          />
        </FormControlContext.Provider>
      </div>
    );
  }
}

ProfileSettings.propTypes = {
  onUpdateDueDate: PropTypes.func,
  onUpdateMessageToReviewers: PropTypes.func,
  onUpdateDueTime: PropTypes.func,
  dueDate: PropTypes.string,
  dueTime: PropTypes.string,
  messageToReviewers: PropTypes.string,
};

export default withTranslations(ProfileSettings);
